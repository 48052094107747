var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "客户分组",
        visible: _vm.showDialog,
        width: "1000px",
        top: "15vh",
        "close-on-click-modal": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "margin-bottom": "10px",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "110px",
                    "margin-right": "10px",
                    "text-align": "right",
                  },
                },
                [_vm._v(" 客户组名称: ")]
              ),
              _c("el-input", {
                staticStyle: { width: "260px" },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { stripe: "", data: _vm.sortList } },
            [
              _vm._l(_vm.columns, function (item, tableIndex) {
                return _c("el-table-column", {
                  key: tableIndex,
                  attrs: {
                    prop: item.prop,
                    label: item.label,
                    width: item.width,
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            item.label == "排序"
                              ? _c(
                                  "div",
                                  [
                                    _c("el-input", {
                                      attrs: { type: "number" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handlSort($event, row)
                                        },
                                      },
                                      model: {
                                        value: row.sort,
                                        callback: function ($$v) {
                                          _vm.$set(row, "sort", _vm._n($$v))
                                        },
                                        expression: "row.sort",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : item.label == "客户名称"
                              ? _c(
                                  "div",
                                  [
                                    _c("client-search", {
                                      attrs: {
                                        "popper-class": "selectDC",
                                        keyword: row.cust_name,
                                      },
                                      on: {
                                        "select-val-alldata": function (
                                          $event
                                        ) {
                                          return _vm.clientSelect($event, row)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _vm._v(" " + _vm._s(row[item.prop]) + " "),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "",
                  align: "center",
                  label: "操作",
                  fixed: "right",
                  width: "120px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleAdd($index, row)
                              },
                            },
                          },
                          [_vm._v(" 添加 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleDelete($index, row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSub } },
            [_vm._v("提交")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showDialog = false
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }