var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "地图分布",
        visible: _vm.showDialog,
        width: "1000px",
        top: "5vh",
        "close-on-click-modal": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "baidu-map",
        {
          staticClass: "map",
          attrs: {
            center: _vm.center,
            zoom: _vm.zoom,
            "scroll-wheel-zoom": true,
          },
          on: { ready: _vm.handler },
        },
        [
          _c("bm-navigation", { attrs: { anchor: "BMAP_ANCHOR_TOP_RIGHT" } }),
          _c("bm-city-list", { attrs: { anchor: "BMAP_ANCHOR_TOP_LEFT" } }),
          _c("bm-geolocation", {
            attrs: {
              anchor: "BMAP_ANCHOR_BOTTOM_RIGHT",
              "show-address-bar": true,
              "auto-location": true,
            },
            on: { locationSuccess: _vm.getLoctionSuccess },
          }),
          _c("bm-view", {
            style: {
              width: "100%",
              height: 500 + "px",
              flex: 1,
              marginBottom: "-30px",
            },
          }),
          _c(
            "bml-marker-clusterer",
            { attrs: { "average-center": true } },
            _vm._l(_vm.list, function (marker, index) {
              return _c(
                "bm-marker",
                {
                  key: index,
                  attrs: {
                    "mass-clear": false,
                    position: { lng: marker.longitude, lat: marker.latitude },
                  },
                  on: {
                    click: function ($event) {
                      return _vm.infoWindowOpen($event, marker, index)
                    },
                  },
                },
                [
                  _c(
                    "bm-info-window",
                    {
                      attrs: { show: marker.show },
                      on: {
                        close: function ($event) {
                          return _vm.infoWindowClose($event, marker)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "18px",
                            "line-height": "36px",
                          },
                        },
                        [_vm._v(" " + _vm._s(marker.cust_name) + " ")]
                      ),
                      _c("div", [_vm._v(_vm._s(marker.boss))]),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "warning", size: "small", icon: "el-icon-close" },
              nativeOn: {
                click: function ($event) {
                  _vm.showDialog = false
                },
              },
            },
            [_vm._v(" 关闭 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }