<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    title="批量添加客户"
    :visible.sync="showDialog"
    top="5vh"
    width="1100px"
  >
    <div class="wrapper">
      <el-form ref="elform" :model="form" inline>
        <el-form-item>
          <sale-Area-select-tree
            ref="saleAreaSelectTree"
            @set-sale-select-id="setSaleSelectId"
          ></sale-Area-select-tree>
        </el-form-item>
        <el-form-item prop="channel_id">
          <el-select
            v-model="form.channel_id"
            clearable
            placeholder="请选择渠道"
            style="width: 160px"
          >
            <el-option
              v-for="item in channelList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="class_id">
          <el-select
            v-model="form.class_id"
            clearable
            placeholder="请选择客户等级"
            style="width: 160px"
          >
            <el-option
              v-for="item in clientLevelList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="staff_id">
          <el-select
            v-model="form.staff_id"
            clearable
            placeholder="请选择业务员"
            style="width: 160px"
          >
            <el-option
              v-for="item in staffList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 建店时间 -->
        <el-form-item prop="time_id">
          <el-select
            v-model="form.time_id"
            clearable
            placeholder="请选择建店时间"
            style="width: 160px"
          >
            <el-option label="建店时间" value="1"></el-option>
            <el-option label="拜访时间" value="2"></el-option>
          </el-select>
        </el-form-item>
        <!-- 开始时间 -->
        <el-form-item prop="start_time">
          <el-date-picker
            v-model="form.start_time"
            type="date"
            clearable
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            placeholder="开始时间"
          ></el-date-picker>
        </el-form-item>
        <!-- 结束时间 -->

        <el-form-item prop="end_time">
          <el-date-picker
            v-model="form.end_time"
            type="date"
            clearable
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            placeholder="结束时间"
          ></el-date-picker>
        </el-form-item>

        <el-form-item>
          <client-search
            ref="clientSearch"
            :popper-class="'selectDC'"
            @select-val="setClientKeyword"
          ></client-search>
        </el-form-item>
        <el-form-item v-if="isLine" label="" prop="">
          <el-checkbox v-model="form.no_cust">无线路店铺</el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQuery">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        ref="form"
        stripe
        :data="list"
        height="320px"
        :row-key="getRowKey"
        @row-click="rowClick"
        @selection-change="setSelectRow"
      >
        <!-- 序号 -->
        <el-table-column align="center" type="index" width="50">
          <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index_) in columns"
                  :key="index_"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <!-- 选择框 -->
        <el-table-column
          align="center"
          type="selection"
          width="50"
          :reserve-selection="true"
        ></el-table-column>
        <el-table-column
          v-for="(item, index_) in finalluColumns"
          :key="index_"
          width=""
          :label="item.label"
          :prop="item.prop"
          align="center"
        ></el-table-column>
      </el-table>
      <el-pagination
        :current-page="form.pageNo"
        :layout="layout"
        :page-size="form.pageSize"
        :total="totalCount"
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>
    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button type="primary" @click="handleAdd">添加</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import SaleAreaSelectTree from '@/baseComponents/saleAreaSelectTree'
  import ClientSearch from '@/baseComponents/clientSearch'
  import {
    getClientLevelList,
    getClientChannelList,
    getStaffList,
    getLinkClientTableList,
    addLinkClient,
  } from '@/api/setPrice'
  export default {
    name: 'BatchAdd',
    components: { SaleAreaSelectTree, ClientSearch },
    props: {
      type: {
        type: Number,
        default: () => 1,
      },
      isSet: {
        type: Boolean,
        default: false,
      },
      isLine: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        clientLevelList: [],
        channelList: [],
        id: '',
        index: '', // 点击row的下标
        loading: true,
        showDialog: false,
        selectRow: [], //已选择的row
        staffList: [], // 业务员下拉列表
        timeList: [], // 建店时间下拉
        totalCount: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        form: {
          no_cust: 0,
          pageNo: 1,
          pageSize: 10,
          area_id: '',
          channel_id: '',
          class_id: '', // 客户等级id
          staff_id: '', //业务员id
          keyword: '',
          time_id: '', // 建店时间
          start_time: '',
          end_time: '',
        },
        // 表格
        list: [],
        // checkList: ['客户名称', '地址', '老板', '电话'],
        // columns: [
        //   {
        //     order: 1,
        //     prop: 'cust_name',
        //     label: '客户名称',
        //     width: 'auto',
        //   },
        //   {
        //     order: 1,
        //     prop: 'address',
        //     label: '地址',
        //     width: 'auto',
        //   },
        //   {
        //     order: 1,
        //     prop: 'boss',
        //     label: '老板',
        //     width: 'auto',
        //   },
        //   {
        //     order: 1,
        //     prop: 'mobile',
        //     label: '电话',
        //     width: 'auto',
        //   },
        // ],
      }
    },
    computed: {
      // checkList: ['客户名称', '地址', '老板', '电话'],
      checkList() {
        if (this.isLine) {
          return ['客户名称', '地址', '线路', '老板', '电话']
        } else {
          return ['客户名称', '地址', '老板', '电话']
        }
      },
      columns() {
        if (this.isLine) {
          // 线路规划
          console.log('线路规划')
          return [
            {
              order: 1,
              prop: 'cust_name',
              label: '客户名称',
              width: 'auto',
            },
            {
              order: 1,
              prop: 'address',
              label: '地址',
              width: 'auto',
            },
            {
              order: 1,
              prop: 'line_name',
              label: '线路',
              width: 'auto',
            },
            {
              order: 1,
              prop: 'boss',
              label: '老板',
              width: 'auto',
            },
            {
              order: 1,
              prop: 'mobile',
              label: '电话',
              width: 'auto',
            },
          ]
        } else {
          return [
            {
              order: 1,
              prop: 'cust_name',
              label: '客户名称',
              width: 'auto',
            },
            {
              order: 1,
              prop: 'address',
              label: '地址',
              width: 'auto',
            },
            {
              order: 1,
              prop: 'boss',
              label: '老板',
              width: 'auto',
            },
            {
              order: 1,
              prop: 'mobile',
              label: '电话',
              width: 'auto',
            },
          ]
        }
      },
      finalluColumns() {
        let arr = []
        this.checkList.forEach((item) => {
          arr.push(this.columns.filter((item2) => item2.label == item)[0])
        })
        return _.sortBy(arr, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.loading = true
          this.resetForm()
        }
      },
    },
    created() {
      // 获取业务员下拉
      getStaffList().then((res) => {
        if (res.code == 200) this.staffList = res.data
      })
      this.initSelect()
    },
    mounted() {},
    methods: {
      async initSelect() {
        let { data, msg, code } = await getClientLevelList()
        if (code == 200) {
          this.clientLevelList = data
        } else {
          this.$message.error(msg)
        }

        let res = await getClientChannelList()
        if (res.code == 200) {
          this.channelList = res.data
        }
      },
      setSaleSelectId(val) {
        this.form.area_id = val
      },
      // 获取表格数据
      async fetchData() {
        var d = null
        if (this.isSet) {
          d = Object.assign(this.form, {
            cust_id: this.cust_id,
            type: this.type,
          })
        } else {
          d = Object.assign(this.form, { id: this.id, type: this.type })
        }
        let { data, code, msg, totalCount } = await getLinkClientTableList(d)
        console.log(data)
        if (code == 200) {
          this.list = data
          this.totalCount = totalCount
          this.loading = false
        }
      },
      // 获取客户等级下拉
      // async getClientLevel() {
      //   let { data, msg, code } = await getClientLevelList()
      //   if (code == 200) {
      //     this.clientLevelList = data
      //   } else {
      //     this.$message.error(msg)
      //   }
      // },

      // 获取渠道下拉
      // async getChannelList() {
      //   let { data, msg, code } = await getClientChannelList()
      //   if (code == 200) {
      //     this.channelList = data
      //   }
      // },
      close() {
        this.showDialog = false
      },
      // 设置勾选的row
      setSelectRow(val) {
        this.selectRow = val
      },
      setClientKeyword(val) {
        this.form.keyword = val
      },
      handleQuery() {
        this.fetchData()
      },
      getRowKey(row) {
        return row.cust_id
      },
      rowClick(row) {
        if (this.type == 2) {
          console.log('rowClick', row)
          this.$emit('choose-row', row, this.index)
          this.close()
        }
      },
      handleAdd() {
        if (this.selectRow.length == 0) {
          this.$message.error('请勾选客户')
          return false
        }
        // addLinkClient
        if (this.selectRow.length !== 0) {
          let data = {
            id: this.id,
            cust_id: this.cust_id,
            customer: JSON.stringify(this.selectRow),
          }
          if (this.type == 1) {
            addLinkClient(data).then((res) => {
              if (res.code == 200) {
                this.$message.success('添加成功')
                this.$emit('refresh')
                this.showDialog = false
              }
            })
          } else if (this.type == 2) {
            // 线路
            console.log(this.selectRow)
            this.$emit('batch-add-client', this.selectRow)
            this.close()
          } else if (this.type == 3) {
            // 打印设置
            console.log('打印设置', data)
            this.$emit('selectGoods', data)
            this.showDialog = false
          }
        }
      },
      // 分页
      handleCurrentChange(val) {
        this.loading = true
        this.form.pageNo = val
        this.fetchData()
        this.loading = false
      },
      handleSizeChange(val) {
        this.loading = true
        this.form.pageSize = val
        this.fetchData()
        this.loading = false
      },
      resetForm() {
        console.log('resetFrom')
        this.$refs['form'].clearSelection()
        this.$refs['elform'].resetFields()
        this.$refs['saleAreaSelectTree'].resetForm()
        this.$refs['clientSearch'].resetForm()
        this.form.keyword = ''
      },
    },
  }
</script>
<style lang="scss" scoped></style>
