var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "批量修改",
        visible: _vm.showDialog,
        width: "500px",
        top: "15vh",
        modal: false,
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-table",
            { attrs: { stripe: "", data: _vm.list } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "修改项目",
                  width: "110",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "selectList",
                  label: "修改为",
                  width: "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.name == "销售区域"
                          ? _c(
                              "div",
                              [
                                _c("sale-area", {
                                  attrs: { "only-check-end": false },
                                  on: {
                                    "set-sale-select-id":
                                      _vm.handleChooseAreaId,
                                  },
                                }),
                              ],
                              1
                            )
                          : row.name == "价格方案"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "160px" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择" + row.name,
                                    },
                                    model: {
                                      value: row.value,
                                      callback: function ($$v) {
                                        _vm.$set(row, "value", $$v)
                                      },
                                      expression: "row.value",
                                    },
                                  },
                                  _vm._l(row.selectList, function (i, idx) {
                                    return _c("el-option", {
                                      key: idx,
                                      attrs: {
                                        value: i.id,
                                        label: i.scheme_name,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : row.name == "线路"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "160px" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择" + row.name,
                                    },
                                    model: {
                                      value: row.value,
                                      callback: function ($$v) {
                                        _vm.$set(row, "value", $$v)
                                      },
                                      expression: "row.value",
                                    },
                                  },
                                  _vm._l(row.selectList, function (i, idx) {
                                    return _c("el-option", {
                                      key: idx,
                                      attrs: {
                                        value: i.id,
                                        label: i.line_name,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : row.name == "网销归属"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "160px" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择" + row.name,
                                    },
                                    model: {
                                      value: row.value,
                                      callback: function ($$v) {
                                        _vm.$set(row, "value", $$v)
                                      },
                                      expression: "row.value",
                                    },
                                  },
                                  _vm._l(row.selectList, function (i, idx) {
                                    return _c("el-option", {
                                      key: idx,
                                      attrs: {
                                        value: i.id,
                                        label: i.user_name,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "160px" },
                                    attrs: {
                                      clearable: "",
                                      multiple: row.duoxuan,
                                      placeholder: "请选择" + row.name,
                                    },
                                    model: {
                                      value: row.value,
                                      callback: function ($$v) {
                                        _vm.$set(row, "value", $$v)
                                      },
                                      expression: "row.value",
                                    },
                                  },
                                  _vm._l(row.selectList, function (i, idx) {
                                    return _c("el-option", {
                                      key: idx,
                                      attrs: { value: i.id, label: i.name },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-popconfirm",
            {
              attrs: { title: "确认要封存选中的店铺吗？" },
              on: { confirm: _vm.handlerSealing },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "180px" },
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [_vm._v(" 封存店铺 ")]
              ),
            ],
            1
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
            [_vm._v("保存")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }