var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "自定义客户组",
        visible: _vm.showDialog,
        width: "1100px",
        top: "5vh",
        "close-on-click-modal": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-container",
            [
              _c(
                "el-aside",
                { staticClass: "Aside", attrs: { width: "300px" } },
                [
                  _c(
                    "el-row",
                    {
                      staticClass: "row-bg",
                      staticStyle: { padding: "0px 0px 10px 0px" },
                      attrs: { type: "flex", justify: "end" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-edit", type: "primary" },
                          on: { click: _vm.handleAdd },
                        },
                        [_vm._v(" 添加分组 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "u-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.l_loading,
                              expression: "l_loading",
                            },
                          ],
                          staticStyle: { width: "100%" },
                          attrs: {
                            height: "510",
                            data: _vm.list,
                            border: "",
                            "row-class-name": _vm.tableRowClassName,
                          },
                          on: { "row-click": _vm.routerClass },
                        },
                        [
                          _vm._l(_vm.tableList, function (tables, index) {
                            return _c("u-table-column", {
                              key: index,
                              attrs: {
                                prop: tables.prop,
                                label: tables.label,
                                width: tables.width,
                                align: tables.align,
                              },
                            })
                          }),
                          _c("u-table-column", {
                            attrs: {
                              prop: "",
                              align: "center",
                              label: "操作",
                              width: "90px",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.handleDeleteGroup(
                                              scope.$index,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 删除 ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-main",
                { staticClass: "Main" },
                [
                  _vm.currentGoodsGroup
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "space-between",
                            "margin-bottom": "10px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "110px",
                                    "margin-right": "10px",
                                    "text-align": "right",
                                  },
                                },
                                [_vm._v(" 客户组名称: ")]
                              ),
                              _c("el-input", {
                                staticStyle: { width: "260px" },
                                model: {
                                  value: _vm.currentGoodsGroup.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.currentGoodsGroup, "name", $$v)
                                  },
                                  expression: "currentGoodsGroup.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-edit",
                                    type: "primary",
                                  },
                                  on: { click: _vm.addGoods },
                                },
                                [_vm._v(" 批量添加 ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "u-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.r_loading,
                          expression: "r_loading",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.rlist,
                        "use-virtual": "",
                        height: "510",
                        border: "",
                        "data-changes-scroll-top": false,
                      },
                    },
                    [
                      _c("u-table-column", {
                        attrs: {
                          width: "80",
                          label: "排序",
                          align: "center",
                          prop: "sort",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-input", {
                                  attrs: { type: "number" },
                                  on: { change: _vm.blur_ },
                                  model: {
                                    value: row.sort_order,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        row,
                                        "sort_order",
                                        _vm._n(
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      )
                                    },
                                    expression: "row.sort_order",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("u-table-column", {
                        attrs: {
                          prop: "cust_name",
                          label: "客户名称",
                          width: "230",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("client-search", {
                                  attrs: {
                                    "popper-class": "selectDC",
                                    keyword: row.cust_name,
                                  },
                                  on: {
                                    "select-val-alldata": function ($event) {
                                      return _vm.clientSelect($event, row)
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("u-table-column", {
                        attrs: {
                          prop: "area_name",
                          label: "销售区域",
                          width: "140",
                          align: "center",
                        },
                      }),
                      _c("u-table-column", {
                        attrs: {
                          prop: "channel_name",
                          label: "渠道",
                          width: "90",
                          align: "center",
                        },
                      }),
                      _c("u-table-column", {
                        attrs: {
                          prop: "class_name",
                          label: "等级",
                          width: "90",
                          align: "center",
                        },
                      }),
                      _c("u-table-column", {
                        attrs: {
                          prop: "",
                          align: "center",
                          label: "操作",
                          fixed: "right",
                          "min-width": "140px",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.tableAdd.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 添加 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.deleteGoods(
                                          scope.$index,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 删除 ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm.currentGoodsGroup
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { float: "right", "margin-top": "10px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.hanldeSave },
                        },
                        [_vm._v(" 保存 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("add", { ref: "add", on: { refresh: _vm.getGroup } }),
      _c("BatchAdd", {
        ref: "batchAdd",
        attrs: { type: 3 },
        on: { selectGoods: _vm.batchAdd },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }