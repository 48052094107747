var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "禁销商品设置",
        visible: _vm.showban,
        width: "600px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showban = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            { ref: "form" },
            [
              _c(
                "el-form-item",
                [
                  _c("el-button", { on: { click: _vm.handleradd } }, [
                    _vm._v("添加商品"),
                  ]),
                  _c("goods-choose-dialog", {
                    ref: "choose",
                    on: { "add-rows": _vm.hanleraddrows },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.tabledata } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "date",
                  label: "商品名称",
                  align: "center",
                  width: "220",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _c("goods-search", {
                          attrs: {
                            goods_keyword: row.goods_name,
                            "f-key": row.goods_name,
                            "goods-id": row.goods_id,
                            rowf: row,
                          },
                          on: {
                            "add-rows": _vm.hanleraddrows,
                            "select-goods-all": function ($event) {
                              return _vm.selectGoods($event, row, $index)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goods_specs",
                  label: "规格",
                  align: "center",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "address", align: "center", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.tabledata.splice($index, 1)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showban = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }