var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "el-form",
        {
          staticClass: "demo-form-inline cxcls",
          attrs: { inline: true, model: _vm.formInline },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("area-select", {
                staticStyle: { width: "180px" },
                attrs: { "show-level": 3 },
                on: { changeArea: _vm.changeArea },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { span: 3 } },
            [
              _c("el-cascader", {
                attrs: {
                  options: _vm.options,
                  props: {
                    checkStrictly: true,
                    value: "id",
                    label: "area_name",
                  },
                  clearable: "",
                  filterable: "",
                  placeholder: "请选择销售区域",
                },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.area_idAll,
                  callback: function ($$v) {
                    _vm.area_idAll = $$v
                  },
                  expression: "area_idAll",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  style: { width: _vm.lablewidth4 },
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "请选择渠道",
                  },
                  model: {
                    value: _vm.formInline.channel_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "channel_id", $$v)
                    },
                    expression: "formInline.channel_id",
                  },
                },
                _vm._l(_vm.channels, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  style: { width: _vm.lablewidth4 },
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择等级",
                  },
                  model: {
                    value: _vm.formInline.class_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "class_id", $$v)
                    },
                    expression: "formInline.class_id",
                  },
                },
                _vm._l(_vm.CustClass, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择线路（可多选）",
                    multiple: "",
                    clearable: "",
                    filterable: "",
                    "collapse-tags": "",
                  },
                  model: {
                    value: _vm.formInline.line_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "line_id", $$v)
                    },
                    expression: "formInline.line_id",
                  },
                },
                _vm._l(_vm.linelists, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.line_name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  style: { width: _vm.lablewidth4 },
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "请选择网销状态",
                  },
                  model: {
                    value: _vm.formInline.is_open_store,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "is_open_store", $$v)
                    },
                    expression: "formInline.is_open_store",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "已开通", value: 1 } }),
                  _c("el-option", { attrs: { label: "未开通", value: 0 } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  style: { width: _vm.lablewidth4 },
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "请选择业务员",
                  },
                  model: {
                    value: _vm.formInline.staff_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "staff_id", $$v)
                    },
                    expression: "formInline.staff_id",
                  },
                },
                _vm._l(_vm.userLists, function (item) {
                  return _c("el-option", {
                    key: item.user_id,
                    attrs: { label: item.user_name, value: item.user_id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  style: { width: _vm.lablewidth4 },
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "地图定位",
                  },
                  model: {
                    value: _vm.formInline.is_map,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "is_map", $$v)
                    },
                    expression: "formInline.is_map",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "有", value: 1 } }),
                  _c("el-option", { attrs: { label: "无", value: 2 } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  style: { width: _vm.lablewidth4 },
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "是否绑定微信",
                  },
                  model: {
                    value: _vm.formInline.is_openid,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "is_openid", $$v)
                    },
                    expression: "formInline.is_openid",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "已绑定", value: 2 } }),
                  _c("el-option", { attrs: { label: "未绑定", value: 1 } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  style: { width: _vm.lablewidth },
                  attrs: { filterable: "", clearable: "", placeholder: "状态" },
                  model: {
                    value: _vm.formInline.is_closed,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "is_closed", $$v)
                    },
                    expression: "formInline.is_closed",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "正常", value: 0 } }),
                  _c("el-option", { attrs: { label: "封存", value: 1 } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "客户分组" },
                  model: {
                    value: _vm.formInline.group_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "group_id", $$v)
                    },
                    expression: "formInline.group_id",
                  },
                },
                _vm._l(_vm.clientGroup, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                style: { width: _vm.lablewidth5 },
                attrs: {
                  placeholder: "请输入客户名称、客户编码、老板姓名、电话、地址",
                  "prefix-icon": "el-icon-search",
                },
                model: {
                  value: _vm.formInline.keyword,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInline, "keyword", $$v)
                  },
                  expression: "formInline.keyword",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-input",
                {
                  style: { width: _vm.formiWidth },
                  attrs: {
                    placeholder: "请输入备注",
                    "prefix-icon": "el-icon-search",
                  },
                  model: {
                    value: _vm.formInline.cust_remark,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "cust_remark", $$v)
                    },
                    expression: "formInline.cust_remark",
                  },
                },
                [_vm._v(" > ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              staticClass: "row-bg btndiv",
              attrs: { type: "flex", justify: "space-between" },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v(" 筛查重复客户 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleMerge },
                    },
                    [_vm._v("合并客户")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleCustomClientGroup },
                    },
                    [_vm._v(" 自定义客户组 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticClass: "row-bg",
                  attrs: { type: "flex", justify: "end", gutter: 10 },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addcustomer },
                    },
                    [_vm._v("添加客户")]
                  ),
                  _c("el-button", { on: { click: _vm.handleImport } }, [
                    _vm._v("导入"),
                  ]),
                  _c("el-button", { on: { click: _vm.handleExport } }, [
                    _vm._v("导出"),
                  ]),
                  _c("el-button", { on: { click: _vm.handleMap } }, [
                    _vm._v("地图分布"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: _vm.title2,
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "客户名称：",
                            "label-width": _vm.formiWidth2,
                            prop: "cust_name",
                          },
                        },
                        [
                          _c("el-input", {
                            style: { width: _vm.formiWidth },
                            attrs: { autocomplete: "off" },
                            model: {
                              value: _vm.form.cust_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cust_name", $$v)
                              },
                              expression: "form.cust_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "编码：",
                            "label-width": _vm.formiWidth2,
                            prop: "cust_pinyin",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.form.cust_code) + " ")]
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "老板姓名：",
                            "label-width": _vm.formiWidth2,
                            prop: "boss",
                          },
                        },
                        [
                          _c("el-input", {
                            style: { width: _vm.formiWidth },
                            attrs: { autocomplete: "off" },
                            model: {
                              value: _vm.form.boss,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "boss", $$v)
                              },
                              expression: "form.boss",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "老板手机号：",
                            "label-width": _vm.formiWidth2,
                            prop: "mobile",
                          },
                        },
                        [
                          _c("el-input", {
                            style: { width: _vm.formiWidth },
                            attrs: { autocomplete: "off" },
                            model: {
                              value: _vm.form.mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "mobile", $$v)
                              },
                              expression: "form.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "网销端密码：",
                            "label-width": _vm.formiWidth2,
                          },
                        },
                        [
                          !_vm.isEdit
                            ? _c("span", [_vm._v("初始密码123456")])
                            : _c("div", [
                                _vm.form.is_open_store == 0
                                  ? _c("span", [_vm._v("未开通")])
                                  : _c(
                                      "div",
                                      [
                                        _c("span", [_vm._v("已开通")]),
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { margin: "0 10px" },
                                            model: {
                                              value: _vm.form.shop_staff,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "shop_staff",
                                                  $$v
                                                )
                                              },
                                              expression: "form.shop_staff",
                                            },
                                          },
                                          _vm._l(
                                            _vm.form.shop_staff_list,
                                            function (list, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: list.user_name,
                                                  value: list.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { margin: "0 10px" },
                                            attrs: { type: "primary" },
                                            on: { click: _vm.handleResetPw },
                                          },
                                          [_vm._v(" 密码重置为123456 ")]
                                        ),
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              "true-label": 1,
                                              "false-label": 0,
                                            },
                                            model: {
                                              value: _vm.jinyongawngxiao,
                                              callback: function ($$v) {
                                                _vm.jinyongawngxiao = $$v
                                              },
                                              expression: "jinyongawngxiao",
                                            },
                                          },
                                          [_vm._v(" 禁用网销 ")]
                                        ),
                                      ],
                                      1
                                    ),
                              ]),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "渠道：",
                            "label-width": _vm.formiWidth2,
                            prop: "channel_id",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              style: { width: _vm.formiWidth },
                              attrs: {
                                filterable: "",
                                placeholder: "请选择渠道",
                              },
                              model: {
                                value: _vm.form.channel_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "channel_id", $$v)
                                },
                                expression: "form.channel_id",
                              },
                            },
                            _vm._l(_vm.channels, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "等级：",
                            "label-width": _vm.formiWidth2,
                            prop: "class_id",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              style: { width: _vm.formiWidth },
                              attrs: { placeholder: "请选择等级" },
                              on: { change: _vm.handlerchange },
                              model: {
                                value: _vm.form.class_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "class_id", $$v)
                                },
                                expression: "form.class_id",
                              },
                            },
                            _vm._l(_vm.CustClass, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "销售区域：",
                            "label-width": _vm.formiWidth2,
                            prop: "area_id",
                          },
                        },
                        [
                          _c("el-cascader", {
                            attrs: {
                              options: _vm.options,
                              props: {
                                checkStrictly: false,
                                value: "id",
                                label: "area_name",
                              },
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择销售区域",
                            },
                            model: {
                              value: _vm.form.area_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "area_id", $$v)
                              },
                              expression: "form.area_id",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: {
                            label: "经纬度：",
                            "label-width": _vm.formiWidth2,
                          },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: { padding: "0" },
                              attrs: { span: 6, offset: 0 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "longitude" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      autocomplete: "off",
                                      prop: "longitude",
                                    },
                                    model: {
                                      value: _vm.form.longitude,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "longitude",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "form.longitude",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "latitude" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      autocomplete: "off",
                                      prop: "latitude",
                                    },
                                    model: {
                                      value: _vm.form.latitude,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "latitude",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "form.latitude",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.dialogVisiblemap = true
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-location",
                                  staticStyle: { color: "#409eff" },
                                }),
                                _vm._v(" 地图定位 "),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "地址：",
                            "label-width": _vm.formiWidth2,
                            prop: "province",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            {
                              staticClass: "row-bg",
                              attrs: { type: "flex", gutter: 10 },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "省级地区" },
                                      on: { change: _vm.choseProvince },
                                      model: {
                                        value: _vm.form.province,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "province", $$v)
                                        },
                                        expression: "form.province",
                                      },
                                    },
                                    _vm._l(_vm.province, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.title,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.form.province
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: { label: "", prop: "city" },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "市级地区",
                                              },
                                              on: { change: _vm.choseCity },
                                              model: {
                                                value: _vm.form.city,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "city",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.city",
                                              },
                                            },
                                            _vm._l(_vm.shi1, function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.title,
                                                  value: item.id,
                                                },
                                              })
                                            }),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.form.city
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: {
                                            label: "",
                                            prop: "district",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "区级地区",
                                              },
                                              on: { change: _vm.choseBlock },
                                              model: {
                                                value: _vm.form.district,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "district",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.district",
                                              },
                                            },
                                            _vm._l(_vm.qu1, function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.title,
                                                  value: item.id,
                                                },
                                              })
                                            }),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "",
                            "label-width": _vm.formiWidth2,
                            prop: "address",
                          },
                        },
                        [
                          _c("el-input", {
                            style: { width: _vm.lablewidth5 },
                            attrs: {
                              autocomplete: "off",
                              placeholder: "请填写详细地址",
                            },
                            model: {
                              value: _vm.form.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "address", $$v)
                              },
                              expression: "form.address",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "线路：",
                            "label-width": _vm.formiWidth2,
                            prop: "line",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              style: { width: _vm.formiWidth },
                              attrs: {
                                placeholder: "请选择线路（可多选）",
                                multiple: "",
                                filterable: "",
                                "collapse-tags": "",
                              },
                              model: {
                                value: _vm.form.line,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "line", $$v)
                                },
                                expression: "form.line",
                              },
                            },
                            _vm._l(_vm.linelists, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.line_name,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务员禁销产品：" } },
                        [
                          _c("div", { on: { click: _vm.handlerban } }, [
                            _c("span", [_vm._v(_vm._s(_vm.form.unsale_num))]),
                            _c("i", {
                              staticClass: "el-icon-edit-outline",
                              staticStyle: { "margin-left": "10px" },
                            }),
                          ]),
                          _c("bangoods", {
                            ref: "ban",
                            on: {
                              getlist: function ($event) {
                                return _vm.handleEdit(1, { id: _vm.editId })
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "默认结算方式：",
                            "label-width": _vm.formiWidth2,
                            prop: "pay_way",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              style: { width: _vm.formiWidth },
                              attrs: { placeholder: "请选择默认结算方式" },
                              model: {
                                value: _vm.form.pay_way,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "pay_way", $$v)
                                },
                                expression: "form.pay_way",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "货到付款", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: "账期结算", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "欠款预警金额：",
                            "label-width": _vm.formiWidth2,
                            prop: "warn_money",
                          },
                        },
                        [
                          _c("el-input", {
                            style: { width: _vm.formiWidth6 },
                            attrs: { autocomplete: "off" },
                            model: {
                              value: _vm.form.warn_money,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "warn_money", _vm._n($$v))
                              },
                              expression: "form.warn_money",
                            },
                          }),
                          _vm._v(" 元 "),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "欠款预警天数：",
                            "label-width": _vm.formiWidth2,
                            prop: "warn_time",
                          },
                        },
                        [
                          _c("el-input", {
                            style: { width: _vm.formiWidth6 },
                            attrs: { autocomplete: "off" },
                            model: {
                              value: _vm.form.warn_time,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "warn_time", _vm._n($$v))
                              },
                              expression: "form.warn_time",
                            },
                          }),
                          _vm._v(" 天 "),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "拜访周期：",
                            "label-width": _vm.formiWidth2,
                            prop: "visit_cycle",
                          },
                        },
                        [
                          _c("el-input", {
                            style: { width: _vm.formiWidth6 },
                            attrs: { autocomplete: "off" },
                            model: {
                              value: _vm.form.visit_cycle,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "visit_cycle", _vm._n($$v))
                              },
                              expression: "form.visit_cycle",
                            },
                          }),
                          _vm._v(" 天 "),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "备注：",
                            "label-width": _vm.formiWidth2,
                            prop: "cust_remark",
                          },
                        },
                        [
                          _c("el-input", {
                            style: { width: _vm.formiWidth },
                            attrs: { autocomplete: "off" },
                            model: {
                              value: _vm.form.cust_remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cust_remark", $$v)
                              },
                              expression: "form.cust_remark",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "状态：",
                            "label-width": _vm.formiWidth2,
                            prop: "is_closed",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.is_closed,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "is_closed", $$v)
                                },
                                expression: "form.is_closed",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("正常"),
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("封存"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.saveType != 2
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("form", 1)
                        },
                      },
                    },
                    [_vm._v(" 保存并新增 ")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("form", _vm.saveType)
                    },
                  },
                },
                [_vm._v(" 保存 ")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.resetForm("form")
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: "地图定位",
            visible: _vm.dialogVisiblemap,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisiblemap = $event
            },
          },
        },
        [
          _c(
            "baidu-map",
            {
              staticClass: "map",
              attrs: {
                center: _vm.center,
                zoom: _vm.zoom,
                "scroll-wheel-zoom": true,
              },
              on: { ready: _vm.handler, click: _vm.clickEvent },
            },
            [
              _c("bm-navigation", {
                attrs: { anchor: "BMAP_ANCHOR_TOP_RIGHT" },
              }),
              _c("bm-city-list", { attrs: { anchor: "BMAP_ANCHOR_TOP_LEFT" } }),
              _c("bm-geolocation", {
                attrs: {
                  anchor: "BMAP_ANCHOR_BOTTOM_RIGHT",
                  "show-address-bar": true,
                  "auto-location": true,
                },
                on: { locationSuccess: _vm.getLoctionSuccess },
              }),
              _c("bm-view", {
                style: {
                  width: "100%",
                  height: _vm.clientHeight + "px",
                  flex: 1,
                  marginBottom: "-30px",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "demo-input-suffix" },
            [
              _c("el-link", { attrs: { type: "info" } }, [_vm._v("lng：")]),
              _c("el-input", {
                staticClass: "lineinput",
                staticStyle: { width: "200px" },
                attrs: { size: "mini" },
                model: {
                  value: _vm.locData.longitude,
                  callback: function ($$v) {
                    _vm.$set(_vm.locData, "longitude", _vm._n($$v))
                  },
                  expression: "locData.longitude",
                },
              }),
              _c("el-link", { attrs: { type: "info" } }, [_vm._v("lat：")]),
              _c("el-input", {
                staticClass: "lineinput",
                staticStyle: { width: "200px" },
                attrs: { size: "mini" },
                model: {
                  value: _vm.locData.latitude,
                  callback: function ($$v) {
                    _vm.$set(_vm.locData, "latitude", _vm._n($$v))
                  },
                  expression: "locData.latitude",
                },
              }),
              _c("el-link", { attrs: { type: "info" } }, [_vm._v("address：")]),
              _c("el-input", {
                staticClass: "lineinput",
                staticStyle: { width: "200px" },
                attrs: { size: "mini" },
                model: {
                  value: _vm.locData.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.locData, "address", $$v)
                  },
                  expression: "locData.address",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "warning",
                    size: "small",
                    icon: "el-icon-close",
                  },
                  nativeOn: {
                    click: function ($event) {
                      _vm.dialogVisiblemap = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-check",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.findlocation.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" 保存 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tableWrap" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "tableSort",
              staticStyle: { "margin-top": "35px" },
              attrs: {
                stripe: "",
                data: _vm.list,
                border: "",
                "row-key": "id",
              },
              on: { "selection-change": _vm.selectTable },
            },
            [
              _c(
                "el-table-column",
                {
                  attrs: {
                    align: "center",
                    type: "index",
                    width: "50",
                    fixed: "left",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            "popper-class": "custom-table-checkbox",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.checkList,
                                callback: function ($$v) {
                                  _vm.checkList = $$v
                                },
                                expression: "checkList",
                              },
                            },
                            _vm._l(_vm.columns, function (item, index) {
                              return _c("el-checkbox", {
                                key: index,
                                attrs: { label: item.label },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", type: "text" },
                              slot: "reference",
                            },
                            [
                              _c("vab-remix-icon", {
                                attrs: { icon: "settings-line" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  type: "selection",
                  width: "50",
                  "reserve-selection": true,
                },
              }),
              _vm._l(_vm.finallyColumns, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    prop: item.prop,
                    align: item.align,
                    label: item.label,
                    width: item.width,
                  },
                  scopedSlots: _vm._u(
                    [
                      item.label === "排序"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-input", {
                                  on: { blur: _vm.blur_ },
                                  model: {
                                    value: row.sort,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        row,
                                        "sort",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "row.sort",
                                  },
                                }),
                              ]
                            },
                          }
                        : item.label == "业务员"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "text-decoration": "underline",
                                      cursor: "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showStaff(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(row.user_count) + "人 ")]
                                ),
                              ]
                            },
                          }
                        : item.label === "状态"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.is_closed == 1
                                  ? _c("span", [_vm._v("封存")])
                                  : _c("span", [_vm._v("正常")]),
                              ]
                            },
                          }
                        : item.label === "网销"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.is_open_store == 1
                                  ? _c("span", [_vm._v("开通")])
                                  : _c("span", [_vm._v("未开通")]),
                              ]
                            },
                          }
                        : item.label === "绑定微信"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.openid == 0
                                  ? _c("span", [_vm._v("未绑定")])
                                  : _c("span", [_vm._v("已绑定")]),
                              ]
                            },
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "160",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                        scope.row.is_closed == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleSeal(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v(" 封存 ")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleSeal(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v(" 解封 ")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "15px" } },
        [
          _c(
            "el-col",
            { attrs: { span: 5 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "warning" },
                  on: { click: _vm.handleBatchEdit },
                },
                [_vm._v("批量修改")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-pagination", {
                staticStyle: { "margin-top": "0" },
                attrs: {
                  "current-page": _vm.currentPage4,
                  layout: _vm.layout,
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("set-component", {
        ref: "setComponent",
        attrs: { id: _vm.cust_id },
        on: { "set-userid": _vm.setUserId, refresh: _vm.fetchData },
      }),
      _c("batch-edit", { ref: "batchEdit", on: { refresh: _vm.fetchData } }),
      _c("import-tpl", { ref: "importTpl" }),
      _c("filter-client", {
        ref: "filterClient",
        on: { refresh: _vm.fetchData },
      }),
      _c("merge-client", {
        ref: "mergeClient",
        on: { refresh: _vm.fetchData },
      }),
      _c("my-map", { ref: "myMap" }),
      _c("customClientGroup", { ref: "customClientGroup" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }