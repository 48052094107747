<template>
  <el-dialog
    title="重复客户"
    :visible.sync="showDialog"
    width="1100px"
    top="5vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <div class="orderTest-container">
      <el-table
        ref="table"
        v-loading="loading"
        stripe
        row-key="id"
        :data="list"
        height="500px"
        @selection-change="selectRow"
      >
        <!-- 选择框 -->
        <el-table-column
          align="center"
          type="selection"
          :reserve-selection="true"
          width="50"
        ></el-table-column>
        <el-table-column
          v-for="(item, tableIndex) in finallyColumns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          width=""
          align="center"
        >
          <template #default="{ row }">
            <div v-if="item.prop == 'photo1'">
              <el-image
                v-if="row.photo1"
                style="width: 100px; height: 100px"
                :src="row.photo1"
                :preview-src-list="[row.photo1]"
              ></el-image>
              <span v-else></span>
            </div>
            <div v-else>
              {{ row[item.prop] }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="form.pageNo"
        :layout="layout"
        :page-size="form.pageSize"
        :total="total"
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>
    <div class="dialog-footer">
      <el-button type="primary" @click="handleMerge">合并</el-button>
      <el-button @click="close">关闭</el-button>
    </div>
    <merge ref="merge" @refresh="fetchData"></merge>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/Employee'
  import _ from 'lodash'
  import merge from './merge'
  export default {
    name: 'FilterCom',
    components: { merge },
    data() {
      return {
        loading: false,
        list: [],
        showDialog: false,
        checkList: ['店铺名称', '联系人', '联系电话', '门头照片', '地址'],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        columns: [
          {
            order: 1,
            label: '店铺名称',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 2,
            label: '联系人',
            prop: 'boss',
            width: '',
          },
          {
            order: 3,
            label: '联系电话',
            prop: 'mobile',
            width: '',
          },
          {
            order: 4,
            label: '门头照片',
            prop: 'photo1',
            width: '',
          },
          {
            order: 5,
            label: '地址',
            prop: 'address',
            width: '360',
          },
        ],
        form: {
          pageSize: 10,
          pageNo: 1,
        },
        currentRow: [],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      showDialog(v) {
        if (v) {
          this.fetchData()
        } else {
          this.currentRow = []
          this.form = {
            pageSize: 10,
            pageNo: 1,
          }
          this.$refs.table.clearSelection()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await postAction(
          '/baseAdmin/customer-info/repeat',
          this.form
        )
        this.total = totalCount
        this.$refs.table.clearSelection()
        this.currentRow = []
        this.list = data
        this.loading = false
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      handleMerge() {
        if (this.currentRow.length != 2) {
          this.$message.error('请选择两个客户合并')
        } else {
          // 合并
          let ids = []
          this.currentRow.forEach((i) => {
            ids.push(i.id)
          })
          // this.$refs.merge.clients = JSON.parse(JSON.stringify(this.currentRow))
          this.$refs.merge.ids = ids
          this.$refs.merge.showDialog = true
        }
      },
      close() {
        this.showDialog = false
      },
      // 勾选一个row
      selectRow(v) {
        console.log(v)
        this.currentRow = v
      },
    },
  }
</script>
<style lang="scss" scoped></style>
