<template>
  <div class="wrapper">
    <!-- NAME[epic=基础] 客户资料 -->
    <el-form :inline="true" :model="formInline" class="demo-form-inline cxcls">
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <area-select
          style="width: 180px"
          :show-level="3"
          @changeArea="changeArea"
        ></area-select>
      </el-form-item>
      <el-form-item :span="3">
        <el-cascader
          v-model="area_idAll"
          :options="options"
          :props="{
            checkStrictly: true,
            value: 'id',
            label: 'area_name',
          }"
          clearable
          filterable
          placeholder="请选择销售区域"
          @change="handleChange"
        ></el-cascader>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="formInline.channel_id"
          filterable
          clearable
          placeholder="请选择渠道"
          :style="{ width: lablewidth4 }"
        >
          <el-option
            v-for="item in channels"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="formInline.class_id"
          clearable
          filterable
          placeholder="请选择等级"
          :style="{ width: lablewidth4 }"
        >
          <el-option
            v-for="item in CustClass"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="formInline.line_id"
          placeholder="请选择线路（可多选）"
          multiple
          clearable
          filterable
          collapse-tags
        >
          <el-option
            v-for="item in linelists"
            :key="item.id"
            :label="item.line_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="formInline.is_open_store"
          filterable
          clearable
          placeholder="请选择网销状态"
          :style="{ width: lablewidth4 }"
        >
          <el-option label="已开通" :value="1"></el-option>
          <el-option label="未开通" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="formInline.staff_id"
          filterable
          clearable
          placeholder="请选择业务员"
          :style="{ width: lablewidth4 }"
        >
          <el-option
            v-for="item in userLists"
            :key="item.user_id"
            :label="item.user_name"
            :value="item.user_id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="formInline.is_map"
          filterable
          clearable
          placeholder="地图定位"
          :style="{ width: lablewidth4 }"
        >
          <el-option label="有" :value="1"></el-option>
          <el-option label="无" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="formInline.is_openid"
          filterable
          clearable
          placeholder="是否绑定微信"
          :style="{ width: lablewidth4 }"
        >
          <el-option label="已绑定" :value="2"></el-option>
          <el-option label="未绑定" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="formInline.is_closed"
          filterable
          clearable
          placeholder="状态"
          :style="{ width: lablewidth }"
        >
          <el-option label="正常" :value="0"></el-option>
          <el-option label="封存" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="formInline.group_id"
          clearable
          placeholder="客户分组"
        >
          <el-option
            v-for="item in clientGroup"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="formInline.keyword"
          placeholder="请输入客户名称、客户编码、老板姓名、电话、地址"
          prefix-icon="el-icon-search"
          :style="{ width: lablewidth5 }"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="formInline.cust_remark"
          placeholder="请输入备注"
          prefix-icon="el-icon-search"
          :style="{ width: formiWidth }"
        >
          >
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
      <el-row type="flex" class="row-bg btndiv" justify="space-between">
        <el-row :gutter="10">
          <el-button type="primary" @click="handleFilter">
            筛查重复客户
          </el-button>
          <el-button type="primary" @click="handleMerge">合并客户</el-button>
          <el-button type="primary" @click="handleCustomClientGroup">
            自定义客户组
          </el-button>
        </el-row>
        <el-row type="flex" class="row-bg" justify="end" :gutter="10">
          <el-button type="primary" @click="addcustomer">添加客户</el-button>
          <!-- <el-button>导入</el-button> -->
          <el-button @click="handleImport">导入</el-button>
          <el-button @click="handleExport">导出</el-button>
          <el-button @click="handleMap">地图分布</el-button>
        </el-row>
      </el-row>
    </el-form>
    <!--  -->
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      :title="title2"
      :visible.sync="dialogFormVisible"
    >
      <el-form ref="form" :model="form" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item
              label="客户名称："
              :label-width="formiWidth2"
              prop="cust_name"
            >
              <el-input
                v-model="form.cust_name"
                autocomplete="off"
                :style="{ width: formiWidth }"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="编码："
              :label-width="formiWidth2"
              prop="cust_pinyin"
            >
              {{ form.cust_code }}
              <!-- <el-input
                v-model="form.cust_pinyin"
                autocomplete="off"
                placeholder="自动生成助记码"
                :style="{ width: formiWidth }"
              ></el-input> -->
            </el-form-item>
            <el-form-item
              label="老板姓名："
              :label-width="formiWidth2"
              prop="boss"
            >
              <el-input
                v-model="form.boss"
                autocomplete="off"
                :style="{ width: formiWidth }"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="老板手机号："
              :label-width="formiWidth2"
              prop="mobile"
            >
              <el-input
                v-model="form.mobile"
                autocomplete="off"
                :style="{ width: formiWidth }"
              ></el-input>
            </el-form-item>
            <el-form-item label="网销端密码：" :label-width="formiWidth2">
              <span v-if="!isEdit">初始密码123456</span>
              <div v-else>
                <span v-if="form.is_open_store == 0">未开通</span>
                <div v-else>
                  <span>已开通</span>
                  <el-select v-model="form.shop_staff" style="margin: 0 10px">
                    <el-option
                      v-for="(list, index) in form.shop_staff_list"
                      :key="index"
                      :label="list.user_name"
                      :value="list.id"
                    />
                  </el-select>
                  <el-button
                    style="margin: 0 10px"
                    type="primary"
                    @click="handleResetPw"
                  >
                    密码重置为123456
                  </el-button>
                  <el-checkbox
                    v-model="jinyongawngxiao"
                    :true-label="1"
                    :false-label="0"
                  >
                    禁用网销
                  </el-checkbox>
                </div>
              </div>
            </el-form-item>
            <el-form-item
              label="渠道："
              :label-width="formiWidth2"
              prop="channel_id"
            >
              <el-select
                v-model="form.channel_id"
                filterable
                placeholder="请选择渠道"
                :style="{ width: formiWidth }"
              >
                <el-option
                  v-for="item in channels"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="等级："
              :label-width="formiWidth2"
              prop="class_id"
            >
              <el-select
                v-model="form.class_id"
                placeholder="请选择等级"
                :style="{ width: formiWidth }"
                @change="handlerchange"
              >
                <el-option
                  v-for="item in CustClass"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="销售区域："
              :label-width="formiWidth2"
              prop="area_id"
            >
              <el-cascader
                v-model="form.area_id"
                :options="options"
                :props="{
                  checkStrictly: false,
                  value: 'id',
                  label: 'area_name',
                }"
                clearable
                filterable
                placeholder="请选择销售区域"
              ></el-cascader>
            </el-form-item>
            <el-form-item
              label="经纬度："
              :label-width="formiWidth2"
              style="margin-bottom: 0"
            >
              <el-col :span="6" :offset="0" style="padding: 0">
                <el-form-item prop="longitude">
                  <el-input
                    v-model.number="form.longitude"
                    autocomplete="off"
                    prop="longitude"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="latitude">
                  <el-input
                    v-model.number="form.latitude"
                    autocomplete="off"
                    prop="latitude"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <span @click="dialogVisiblemap = true">
                  <i class="el-icon-location" style="color: #409eff"></i>
                  地图定位
                </span>
              </el-col>
            </el-form-item>
            <el-form-item
              label="地址："
              :label-width="formiWidth2"
              prop="province"
            >
              <el-row type="flex" class="row-bg" :gutter="10">
                <el-col :span="8">
                  <el-select
                    v-model="form.province"
                    placeholder="省级地区"
                    @change="choseProvince"
                  >
                    <el-option
                      v-for="item in province"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-col>
                <el-col v-if="form.province" :span="8">
                  <el-form-item label="" prop="city" style="margin-bottom: 0">
                    <el-select
                      v-model="form.city"
                      placeholder="市级地区"
                      @change="choseCity"
                    >
                      <el-option
                        v-for="item in shi1"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col v-if="form.city" :span="8">
                  <el-form-item
                    label=""
                    prop="district"
                    style="margin-bottom: 0"
                  >
                    <el-select
                      v-model="form.district"
                      placeholder="区级地区"
                      @change="choseBlock"
                    >
                      <el-option
                        v-for="item in qu1"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="" :label-width="formiWidth2" prop="address">
              <el-input
                v-model="form.address"
                autocomplete="off"
                placeholder="请填写详细地址"
                :style="{ width: lablewidth5 }"
              ></el-input>
            </el-form-item>
            <el-form-item label="线路：" :label-width="formiWidth2" prop="line">
              <el-select
                v-model="form.line"
                placeholder="请选择线路（可多选）"
                :style="{ width: formiWidth }"
                multiple
                filterable
                collapse-tags
              >
                <el-option
                  v-for="item in linelists"
                  :key="item.id"
                  :label="item.line_name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="业务员禁销产品：">
              <div @click="handlerban">
                <span>{{ form.unsale_num }}</span>
                <i class="el-icon-edit-outline" style="margin-left: 10px"></i>
              </div>
              <bangoods ref="ban" @getlist="handleEdit(1, { id: editId })" />
            </el-form-item>
            <el-form-item
              label="默认结算方式："
              :label-width="formiWidth2"
              prop="pay_way"
            >
              <el-select
                v-model="form.pay_way"
                placeholder="请选择默认结算方式"
                :style="{ width: formiWidth }"
              >
                <el-option label="货到付款" :value="1"></el-option>
                <el-option label="账期结算" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="欠款预警金额："
              :label-width="formiWidth2"
              prop="warn_money"
            >
              <el-input
                v-model.number="form.warn_money"
                autocomplete="off"
                :style="{ width: formiWidth6 }"
              ></el-input>
              元
            </el-form-item>
            <el-form-item
              label="欠款预警天数："
              :label-width="formiWidth2"
              prop="warn_time"
            >
              <el-input
                v-model.number="form.warn_time"
                autocomplete="off"
                :style="{ width: formiWidth6 }"
              ></el-input>
              天
            </el-form-item>
            <el-form-item
              label="拜访周期："
              :label-width="formiWidth2"
              prop="visit_cycle"
            >
              <el-input
                v-model.number="form.visit_cycle"
                autocomplete="off"
                :style="{ width: formiWidth6 }"
              ></el-input>
              天
            </el-form-item>
            <el-form-item
              label="备注："
              :label-width="formiWidth2"
              prop="cust_remark"
            >
              <el-input
                v-model="form.cust_remark"
                autocomplete="off"
                :style="{ width: formiWidth }"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="状态："
              :label-width="formiWidth2"
              prop="is_closed"
            >
              <el-radio-group v-model="form.is_closed">
                <el-radio :label="0">正常</el-radio>
                <el-radio :label="1">封存</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          v-if="saveType != 2"
          type="danger"
          @click="submitForm('form', 1)"
        >
          保存并新增
        </el-button>
        <!-- clientlevelCreate_fun() -->
        <el-button type="primary" @click="submitForm('form', saveType)">
          保存
        </el-button>
        <el-button @click="resetForm('form')">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 地图  -->
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="地图定位"
      :visible.sync="dialogVisiblemap"
      width="70%"
    >
      <baidu-map
        class="map"
        :center="center"
        :zoom="zoom"
        :scroll-wheel-zoom="true"
        @ready="handler"
        @click="clickEvent"
      >
        <!--        ak="FzAhPfin2UmY2ZwHiqmGAl2XUlfSxUkY"-->
        <!-- 地图控件位置 -->
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
        <!-- 城市列表 -->
        <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
        <!-- 定位当前位置 -->
        <bm-geolocation
          anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
          :show-address-bar="true"
          :auto-location="true"
          @locationSuccess="getLoctionSuccess"
        ></bm-geolocation>
        <!-- 地图容器 -->
        <bm-view
          :style="{
            width: '100%',
            height: clientHeight + 'px',
            flex: 1,
            marginBottom: '-30px',
          }"
        ></bm-view>
      </baidu-map>
      <div class="demo-input-suffix">
        <el-link type="info">lng：</el-link>
        <el-input
          v-model.number="locData.longitude"
          class="lineinput"
          style="width: 200px"
          size="mini"
        ></el-input>
        <el-link type="info">lat：</el-link>
        <el-input
          v-model.number="locData.latitude"
          class="lineinput"
          style="width: 200px"
          size="mini"
        ></el-input>
        <el-link type="info">address：</el-link>
        <el-input
          v-model="locData.address"
          class="lineinput"
          style="width: 200px"
          size="mini"
        ></el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="warning"
          size="small"
          icon="el-icon-close"
          @click.native="dialogVisiblemap = false"
        >
          取消
        </el-button>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-check"
          @click.native="findlocation"
        >
          保存
        </el-button>
      </div>
    </el-dialog>
    <!-- 表格 -->
    <div class="tableWrap">
      <el-table
        ref="tableSort"
        v-loading="loading"
        stripe
        :data="list"
        border
        row-key="id"
        style="margin-top: 35px"
        @selection-change="selectTable"
      >
        <!-- 序号 -->
        <el-table-column align="center" type="index" width="50" fixed="left">
          <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in columns"
                  :key="index"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <!-- 选择框 -->
        <el-table-column
          align="center"
          type="selection"
          width="50"
          :reserve-selection="true"
        ></el-table-column>
        <el-table-column
          v-for="(item, index) in finallyColumns"
          :key="index"
          :prop="item.prop"
          :align="item.align"
          :label="item.label"
          :width="item.width"
        >
          <!-- 排序 -->
          <template v-if="item.label === '排序'" #default="{ row }">
            <el-input v-model.trim="row.sort" @blur="blur_"></el-input>
          </template>
          <template v-else-if="item.label == '业务员'" #default="{ row }">
            <span
              style="text-decoration: underline; cursor: pointer"
              @click="showStaff(row)"
            >
              {{ row.user_count }}人
            </span>
          </template>
          <template v-else-if="item.label === '状态'" #default="{ row }">
            <span v-if="row.is_closed == 1">封存</span>
            <span v-else>正常</span>
          </template>
          <template v-else-if="item.label === '网销'" #default="{ row }">
            <span v-if="row.is_open_store == 1">开通</span>
            <span v-else>未开通</span>
          </template>
          <template v-else-if="item.label === '绑定微信'" #default="{ row }">
            <span v-if="row.openid == 0">未绑定</span>
            <span v-else>已绑定</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.$index, scope.row)">
              编辑
            </el-button>
            <el-button
              v-if="scope.row.is_closed == 0"
              size="mini"
              type="text"
              @click="handleSeal(scope.$index, scope.row)"
            >
              封存
            </el-button>
            <el-button
              v-else
              size="mini"
              type="text"
              @click="handleSeal(scope.$index, scope.row)"
            >
              解封
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-row style="margin-top: 15px">
      <el-col :span="5">
        <el-button type="warning" @click="handleBatchEdit">批量修改</el-button>
      </el-col>
      <el-col :span="12">
        <el-pagination
          :current-page="currentPage4"
          :layout="layout"
          :total="total"
          style="margin-top: 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </el-col>
    </el-row>
    <set-component
      :id="cust_id"
      ref="setComponent"
      @set-userid="setUserId"
      @refresh="fetchData"
    ></set-component>
    <batch-edit ref="batchEdit" @refresh="fetchData"></batch-edit>
    <import-tpl ref="importTpl"></import-tpl>
    <filter-client ref="filterClient" @refresh="fetchData"></filter-client>
    <merge-client ref="mergeClient" @refresh="fetchData"></merge-client>
    <my-map ref="myMap"></my-map>

    <customClientGroup ref="customClientGroup"></customClientGroup>
  </div>
</template>
<script>
  import _ from 'lodash'
  import MyMap from './components/map'
  import SetComponent from '@/baseComponents/setStaff'
  import { clientExport } from '@/api/ClientDetail'
  import {
    BaiduMap,
    BmNavigation,
    BmView,
    BmGeolocation,
    BmCityList,
  } from 'vue-baidu-map'
  import avatar from '@/assets/map_marker_check.png'
  import BatchEdit from './components/batchEdit'
  import {
    clientIndex,
    clientCreate,
    clientUpdate,
    clientDetail,
    clientStatus,
    clientAreaList,
    clientCustChannel,
    clientCustClass,
    clientLineList,
    clientUserList,
    clientSelectArea,
  } from '@/api/ClientDetail'
  import { setting } from '@/config/default'
  import AreaSelect from '@/baseComponents/areaSelect'

  import ImportTpl from '@/views/index/components/importTpl.vue'
  import filterClient from './components/filter'
  import mergeClient from './components/merge'
  import { postAction } from '@/api/Employee'
  import customClientGroup from '@/baseComponents/customClientGroup'
  import Bangoods from './components/bangoods'
  export default {
    name: 'ClientDetail',
    components: {
      Bangoods,
      SetComponent,
      BaiduMap,
      BmNavigation,
      AreaSelect,
      BmView,
      BmGeolocation,
      BmCityList,
      BatchEdit,
      ImportTpl,
      filterClient,
      mergeClient,
      MyMap,
      customClientGroup,
    },
    data() {
      return {
        // 是否是添加
        cust_id: 0,
        title: '指定业务员',
        rules: {
          province: [
            {
              required: true,
              message: '请输入地址',
              trigger: 'blur',
            },
          ],
          cust_name: [
            { required: true, message: '请输入客户名称', trigger: 'blur' },
          ],
          boss: [
            { required: true, message: '请输入老板姓名', trigger: 'blur' },
          ],
          // mobile: [
          //   { required: true, message: '请输入20位以内电话', trigger: 'blur' },
          //   {
          //     min: 1,
          //     max: 20,
          //     message: '请输入20位以内电话',
          //     trigger: 'blur',
          //   },
          // ],
          // province: [
          //   { required: true, message: '请选择省份', trigger: 'blur' },
          // ],
          // city: [{ required: true, message: '请选择市区', trigger: 'blur' }],
          // district: [
          //   { required: true, message: '请选择区域', trigger: 'blur' },
          // ],
          // address: [
          //   { required: true, message: '请输入详细地址', trigger: 'blur' },
          // ],
        },
        total: 0,
        jinyongawngxiao: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        area_idAll: [],
        options: [],
        loading: true,
        channels: [],
        CustClass: [],
        linelists: [],
        userLists: [],
        shi_z: '',
        currentRow: [],
        // 表格
        checkList: [
          '排序',
          '编码',
          '客户名称',
          '渠道',
          '客户等级',
          '销售区域',
          '老板',
          '老板手机',
          '网销',
          // '绑定微信',
          '业务员',
          '创建时间',
          '最近下单时间',
          '地址',

          '备注',
          '状态',
        ],
        columns: [
          {
            order: 1,
            label: '排序',
            align: 'center',
            width: '50px',
            prop: 'sort',
            sortable: false,
          },
          {
            order: 1.1,
            prop: 'cust_code',
            align: 'center',
            label: '编码',
            width: '100px',
          },
          {
            order: 2,
            prop: 'cust_name',
            align: 'center',
            label: '客户名称',
            width: '150px',
          },
          {
            order: 3,
            prop: 'channel_name',
            align: 'center',
            label: '渠道',
            width: '80px',
          },
          {
            order: 4,
            prop: 'class_name',
            align: 'center',
            label: '客户等级',
            width: '80px',
          },
          {
            order: 5,
            prop: 'area_name',
            align: 'center',
            label: '销售区域',
            width: '120px',
          },
          {
            order: 6,
            prop: 'boss',
            align: 'center',
            label: '老板',
            width: '90px',
          },
          {
            order: 7,
            prop: 'mobile',
            align: 'center',
            label: '老板手机',
            width: '110px',
          },
          {
            order: 7.5,
            prop: 'address',
            align: 'center',
            label: '地址',
            width: '150px',
          },

          // {
          //   order: 9,
          //   prop: 'openid',
          //   align: 'center',
          //   label: '绑定微信',
          //   width: '80px',
          // },
          {
            order: 10,
            prop: 'user_count',
            align: 'center',
            label: '业务员',
            width: '80px',
          },
          {
            order: 11,
            prop: 'create_at',
            align: 'center',
            label: '创建时间',
            width: '110px',
          },
          {
            order: 12,
            prop: 'last_sales_time',
            align: 'center',
            label: '最近下单时间',
            width: '110px',
          },
          {
            order: 11.5,
            prop: 'is_open_store',
            align: 'center',
            label: '网销',
            width: '90px',
          },
          {
            order: 13,
            prop: 'cust_remark',
            align: 'center',
            label: '备注',
            width: '110px',
          },
          {
            order: 14,
            prop: 'is_closed',
            align: 'center',
            label: '状态',
            width: '110px',
          },
        ],
        list: [],
        selectRow: [],
        //
        center: { lng: 121.833138, lat: 39.081725 },
        zoom: 12,
        locData: {
          longitude: '',
          latitude: '',
          address: '',
          loc_sheng: '',
          loc_shi: '',
          loc_qu: '',
        },
        clientHeight:
          document.documentElement.clientHeight / 2 < 100
            ? '200'
            : document.documentElement.clientHeight / 2, // 屏幕高度
        avatar: avatar,
        dialogVisiblemap: false,
        tableData13: [
          {
            dep: '部门一',
            name: '王小虎',
            moblie: '14323456789',
          },
        ],
        salesmandata: [
          {
            date: '2016-05-03',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1518 弄',
          },
          {
            date: '2016-05-02',
            name: '王2小虎',
            address: '上海市普陀区金沙江路 1518 弄',
          },
          {
            date: '2016-05-04',
            name: '王小3虎',
            address: '上海市普陀区金沙江路 1518 弄',
          },
        ],
        multipleSelection: [],
        dep: [
          {
            name: '部门一',
          },
          {
            name: '部门二',
          },
        ],
        isEdit: false,
        editId: '',
        dialogVisible: false,
        //mapJson: '../static/json/map.json',
        tableData1: [
          {
            id: 1,
            name: '庚辛源商贸',
            channel: '商超',
            level: 'A',
            area: '张家口',
            boss: '邢老板',
            bosspho: '13915236688',
            Netsales: '已开通',
            WeChat: '是',
            salesman: '6',
            Creattime: '2020-3-15',
            ordertime: '2020-3-15',
            type: '正常',
          },
          {
            id: 1,
            name: '庚辛源商贸',
            channel: '商超',
            level: 'A',
            area: '张家口',
            boss: '邢老板',
            bosspho: '13915236688',
            Netsales: '已开通',
            WeChat: '是',
            salesman: '1',
            Creattime: '2020-3-15',
            ordertime: '2020-3-15',
            type: '正常',
          },
          {
            id: 1,
            name: '庚辛源商贸',
            channel: '商超',
            level: 'A',
            area: '张家口',
            boss: '邢老板',
            bosspho: '13915236688',
            Netsales: '已开通',
            WeChat: '是',
            salesman: '6',
            Creattime: '2020-3-15',
            ordertime: '2020-3-15',
            type: '正常',
          },
        ],
        time: [],
        clientGroup: [],
        saveType: 0, //2,编辑入口(保存) ，0添加入口（保存）=[!2，不显示“保存并新增”]
        dialogFormVisible: false,

        form: {
          cust_name: '',
          cust_pinyin: '',
          class_id: '',
          channel_id: '',
          area_id: '',
          license: '',
          boss: '',
          mobile: '',
          pwd: '',
          province: '',
          city: '',
          district: '',
          address: '',
          pay_way: '',
          visit_cycle: '',
          warn_time: '',
          warn_money: '',
          is_closed: '',
          longitude: '',
          latitude: '',
          cust_remark: '',
          line: [],
        },
        lablewidth: '100px',
        lablewidth4: '150px',
        lablewidth5: '350px',
        formInline: {
          pageNo: '1',
          pageSize: '10',
          area_id: '',
          channel_id: '',
          class_id: '',
          line_id: '',
          is_open_store: '',
          staff_id: '',
          is_map: '',
          is_openid: '',
          is_closed: 0,
          keyword: '',
        },
        formLabelWidth: '160px',
        formiWidth2: '120px',
        formiWidth6: '60px',
        formiWidth: '200px',
        province: '',
        //sheng: '',
        shi1: [],
        qu1: [],
        city: '',
        block: '',
        currentPage1: 3,
        currentPage4: 1,
        listdataAll: {
          pageNo: 1,
          pageSize: -1,
        },
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
      title2() {
        if (!this.isEdit) {
          return '添加客户'
        } else {
          return '编辑客户'
        }
      },
    },
    watch: {
      dialogFormVisible(val) {
        if (!val) {
          delete this.form.id
          this.saveType = 0
        } else {
        }
      },
      time(val) {
        if (val) {
          this.formInline.start_time = val[0]
          this.formInline.end_time = val[1]
        } else {
          this.formInline.start_time = ''
          this.formInline.end_time = ''
        }
      },
    },
    created() {
      this.initSelect()
      this.sort_()
      this.fetchData()
      this.clientAreafun()
      this.clientCustChannelfun()
      this.clientCustClassfun()
      this.clientLineListfun()
      this.clientUserListfun()
    },
    methods: {
      handlerban() {
        this.$refs.ban.form.id = this.form.id
        this.$refs.ban.handlerinfo()
        this.$refs.ban.showban = true
      },
      async initSelect() {
        let { data } = await postAction('/baseAdmin/customer-group/index', {
          pageSize: -1,
        })
        this.clientGroup = data
      },
      //客户等级
      handlerchange(val) {
        console.log(val)
        this.CustClass.forEach((list) => {
          if (list.id == val) {
            this.form.warn_time = list.warn_time
            this.form.warn_money = list.warn_money
            this.form.visit_cycle = list.visit_cycle
          }
        })
      },
      setUserId(val) {
        console.log(val, '')
      },
      //查询-销售区域
      clientAreafun() {
        var that = this
        clientAreaList(that.listdataAll).then((d) => {
          if (d.code == 200) {
            console.log('销售区域下拉', d)
            that.options = this.treeData(d.data)
          }
        })
      },
      // 递归处理空 children
      treeData(arr) {
        arr.forEach((item) => {
          if (item.children.length == 0) {
            item.children = undefined
          } else {
            this.treeData(item.children)
          }
        })
        return arr
      },
      //查询-渠道
      clientCustChannelfun() {
        var that = this
        clientCustChannel(that.listdataAll).then((d) => {
          if (d.code == 200) {
            that.channels = d.data
          }
        })
      },
      showStaff(row) {
        console.log(row)
        this.cust_id = Number(row.id)
        this.$refs.setComponent.showDialog = true
      },
      //T添加-关闭
      resetForm(form) {
        this.$refs[form].resetFields()
        this.dialogFormVisible = false
      },
      //查询-等级
      clientCustClassfun() {
        var that = this
        clientCustClass().then((d) => {
          if (d.code == 200) {
            that.CustClass = d.data
            console.log(d.data, '客户等级')
          }
        })
      },
      //查询-线路
      clientLineListfun() {
        var that = this
        clientLineList().then((d) => {
          if (d.code == 200) {
            that.linelists = d.data
          }
        })
      },
      //查询-业务员
      clientUserListfun() {
        var that = this
        clientUserList().then((d) => {
          if (d.code == 200) {
            that.userLists = d.data
          }
        })
      },
      //封存
      handleSeal(index, row) {
        var that = this
        var tips = '封存'
        var is_closeds = row.is_closed
        if (is_closeds == 1) {
          tips = '解封'
        }
        that
          .$confirm('确定要' + tips + '吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
            clientStatus({ id: row.id }).then((d) => {
              if (d.code == 200) {
                that.fetchData()
                that.$message({
                  type: 'success',
                  message: tips + '成功!',
                })
              }
            })
          })
          .catch(() => {
            that.$message({
              type: 'info',
              message: '已取消' + tips,
            })
          })
      },
      // 获取表格数据
      async fetchData() {
        let { data, totalCount } = await clientIndex(this.formInline)
        this.list = data
        this.total = totalCount
        this.selectRow = []
        this.$refs.tableSort.clearSelection()
        this.loading = false
      },
      // 自定义客户组
      handleCustomClientGroup() {
        this.$refs.customClientGroup.showDialog = true
      },
      // 排序输入框失去焦点 排序
      blur_() {
        this.sort_()
      },
      // 排序
      sort_() {
        this.list = _.sortBy(this.list, (item) => item.sort)
      },
      changeArea(v) {
        this.formInline.address_id = v
      },
      //
      handler({ BMap, map }) {
        let _this = this
        // 设置一个临时变量指向vue实例，因为在百度地图回调里使用this，指向的不是vue实例；
        var geolocation = new BMap.Geolocation()
        geolocation.getCurrentPosition(
          function (r) {
            console.log(r)
            _this.center = { lng: r.longitude, lat: r.latitude } // 设置center属性值
            _this.autoLocationPoint = { lng: r.longitude, lat: r.latitude } // 自定义覆盖物
            _this.initLocation = true
          },
          { enableHighAccuracy: true }
        )

        window.map = map
      },
      submitForm(formName, types) {
        this.form.area_id = this.form.area_id
          ? this.form.area_id[this.form.area_id.length - 1]
          : ''
        console.log(this.form, '提交的总数据')
        this.$refs[formName].validate((valid) => {
          var that = this
          if (valid) {
            if (types == 2) {
              console.log(types)
              let data = JSON.parse(JSON.stringify(that.form))
              // data.line = JSON.stringify(data.line)
              data.longitude =
                data.longitude == '' ? '' : JSON.stringify(data.longitude)
              data.latitude =
                data.latitude == '' ? '' : JSON.stringify(data.latitude)
              data.is_enable_store = this.jinyongawngxiao
              console.log(data, '组合好的数据')
              clientUpdate(data).then((d) => {
                if (d.code == 200) {
                  this.$message({
                    message: d.msg,
                    type: 'success',
                  })
                  that.fetchData()
                  that.dialogFormVisible = false
                } else {
                  this.$alert(d.msg, '提示')
                }
              })
            } else {
              console.log(types)
              let data = JSON.parse(JSON.stringify(that.form))
              data.longitude =
                data.longitude == '' ? '' : JSON.stringify(data.longitude)
              data.latitude =
                data.latitude == '' ? '' : JSON.stringify(data.latitude)
              // data.line = JSON.stringify(data.line)
              data.is_enable_store = this.jinyongawngxiao
              clientCreate(data).then((d) => {
                if (d.code == 200) {
                  this.$message({
                    message: d.msg,
                    type: 'success',
                  })
                  that.fetchData()
                  if (types == 1) {
                    that.addcustomer()
                  } else {
                    that.dialogFormVisible = false
                  }
                } else {
                  this.$alert(d.msg, '提示')
                }
              })
            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      //点击地图监听
      clickEvent(e) {
        var that = this
        map.clearOverlays()

        let Icon_0 = new BMap.Icon(that.avatar, new BMap.Size(64, 64), {
          anchor: new BMap.Size(18, 32),
          imageSize: new BMap.Size(36, 36),
        })
        var myMarker = new BMap.Marker(
          new BMap.Point(e.point.lng, e.point.lat),
          { icon: Icon_0 }
        )
        map.addOverlay(myMarker)
        //用所定位的经纬度查找所在地省市街道等信息
        var point = new BMap.Point(e.point.lng, e.point.lat)
        var gc = new BMap.Geocoder()
        let _this = this
        gc.getLocation(point, function (rs) {
          console.log(rs)
          console.log(rs.addressComponents.city)
          var addComp = rs.addressComponents
          // console.log(rs.address) //地址信息
          _this.locData.address = rs.address
          _this.locData.loc_sheng = rs.addressComponents.province
          _this.locData.loc_shi = rs.addressComponents.city
          _this.locData.loc_qu = rs.addressComponents.district
        })
        this.locData.longitude = e.point.lng
        this.locData.latitude = e.point.lat
      },
      //定位成功回调
      getLoctionSuccess(point, AddressComponent, marker) {
        var that = this
        map.clearOverlays()
        let Icon_0 = new BMap.Icon(that.avatar, new BMap.Size(64, 64), {
          anchor: new BMap.Size(18, 32),
          imageSize: new BMap.Size(36, 36),
        })
        var myMarker = new BMap.Marker(
          new BMap.Point(point.point.lng, point.point.lat),
          { icon: Icon_0 }
        )
        map.addOverlay(myMarker)
        this.locData.longitude = point.point.lng
        this.locData.latitude = point.point.lat
      },
      findlocation() {
        var that = this
        that.form.address = that.locData.address
        that.form.longitude = that.locData.longitude
        that.form.latitude = that.locData.latitude
        for (var i = 0; i < that.province.length; i++) {
          if (that.locData.loc_sheng == that.province[i].title) {
            that.form.province = that.province[i].id
            that.choseProvince(that.form.province, 2)
            console.log(that.shi1)
          }
        }
        that.shi_z = that.locData.loc_shi
        that.qu_z = that.locData.loc_qu
        that.dialogVisiblemap = false
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.formInline.pageSize = val
        this.loading = true
        this.fetchData()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.formInline.pageNo = val
        this.loading = true
        this.fetchData()
      },
      handleClick2(row) {
        console.log(row)
      },
      // 编辑入口
      handleEdit(index, row) {
        console.log('回调执行了')
        this.editId = row.id
        this.isEdit = true
        var that = this
        that.getCityData()
        clientDetail({ id: row.id }).then((d) => {
          console.log(d, '用户详情')
          that.form = d.data
          that.form.province = Number(d.data.province)
          that.form.city = Number(d.data.city)
          that.form.district = Number(d.data.district)
          that.form.pay_way = Number(d.data.pay_way)
          that.form.is_closed = Number(d.data.is_closed)
          var ids = []
          that.form.area_id.forEach((list) => {
            list = Number(list)
            ids.push(Number(list))
          })
          that.form.area_id = ids
          this.jinyongawngxiao = d.is_enable_store
          // console.log(that.form.area_id, '区域id')
          this.fetchCity()
          this.fetchArea()
          // // that.form.area_id[0] = Number(d.data.area_id[0])
          // that.choseProvince(that.form.province, 1)
          // that.choseCity(that.form.city)
        })

        that.saveType = 2
        that.dialogFormVisible = true
      },
      handleDelete(index, row) {
        console.log(index, row)
      },
      /**
       * 李汉 fun
       */
      // 重置密码
      handleResetPw() {
        this.$confirm('是否要将客户登录网销密码重置为123456?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          postAction('/baseAdmin/customer-info/reset-pwd', {
            id: this.form.id,
          }).then((r) => {
            this.$message.success(r.msg)
          })
        })
      },
      // 根据省ID获取城市下拉
      fetchCity() {
        clientSelectArea({ parentid: this.form.province }).then((r) => {
          console.log('rrr', r)
          this.shi1 = r.data
        })
      },
      // 根据城市ID获取区下拉
      fetchArea() {
        clientSelectArea({ parentid: this.form.city }).then((r) => {
          console.log('rrr', r)
          this.qu1 = r.data
        })
      },
      /**
       * end
       */
      //添加区域中 选择上级区域值
      handleChange(value) {
        var that = this
        that.formInline.area_id = value[value.length - 1]
      },
      onSubmit() {
        var that = this
        that.formInline.pageNo = 1
        that.loading = true
        console.log('submit!')
        console.log(that.formInline)
        that.fetchData()
      },
      handleImport() {
        this.$refs.importTpl.data.type = 2
        this.$refs.importTpl.title = '批量导入客户'
        this.$refs.importTpl.showDialog = true
      },
      addcustomer() {
        this.isEdit = false
        var that = this
        that.dialogFormVisible = true
        that.getCityData()
        that.form = {
          cust_name: '',
          cust_pinyin: '',
          class_id: '',
          channel_id: '',
          area_id: '',
          license: '',
          boss: '',
          mobile: '',
          pwd: '',
          province: '',
          city: '',
          district: '',
          address: '',
          pay_way: '',
          visit_cycle: '',
          warn_time: '',
          warn_money: '',
          is_closed: '',
          longitude: '',
          latitude: '',
          cust_remark: '',
        }
      },
      getCityData: function () {
        var that = this
        clientSelectArea().then((d) => {
          if (d.code == 200) {
            that.province = d.data
          }
        })
      },
      // 选省
      choseProvince: function (e, type) {
        console.log(e + 'zzzz')
        for (var index2 in this.province) {
          if (e === this.province[index2].id) {
            console.log(this.province[index2].id + 'this.province[index2].id') //你选择的省级编码
            var ids = this.province[index2].id
            var that = this
            clientSelectArea({ parentid: ids }).then((d) => {
              if (d.code == 200) {
                that.shi1 = d.data
                if (type == 2) {
                  for (var i = 0; i < that.shi1.length; i++) {
                    if (that.shi_z == that.shi1[i].title) {
                      that.form.city = that.shi1[i].id
                      var ids2 = that.shi1[i].id
                      clientSelectArea({ parentid: ids2 }).then((d) => {
                        if (d.code == 200) {
                          that.qu1 = d.data
                          for (var i = 0; i < that.qu1.length; i++) {
                            if (that.qu_z == that.qu1[i].title) {
                              that.form.district = that.qu1[i].id
                            }
                          }
                        }
                      })
                    }
                  }
                } else {
                  that.form.city = that.shi1[0].id
                  var ids2 = that.shi1[0].id
                  clientSelectArea({ parentid: ids2 }).then((d) => {
                    if (d.code == 200) {
                      that.qu1 = d.data
                      that.form.district = that.qu1[0].id
                    }
                  })
                }
              }
            })
          }
        }
      },
      // 选市
      choseCity: function (e) {
        for (var index3 in this.shi1) {
          if (e === this.shi1[index3].id) {
            var ids = this.shi1[index3].id
            var that = this
            clientSelectArea({ parentid: ids }).then((d) => {
              if (d.code == 200) {
                that.qu1 = d.data
                that.form.district = that.qu1[0].id
              }
            })
          }
        }
      },
      // 选区
      choseBlock: function (e) {
        this.E = e
        // console.log(this.E)
      },
      // 导出
      handleExport() {
        console.log('导出', this.formInline)
        clientExport(this.formInline).then((res) => {
          console.log('res', res)
          let a = document.createElement('a')
          let blob = new Blob([res], {
            type: 'application/vnd.ms-excel',
          })
          let url = window.URL.createObjectURL(blob)
          a.setAttribute('href', url)
          a.setAttribute('download', '客户资料.xlsx')
          a.click()
        })
      },
      handleBatchEdit() {
        if (this.selectRow.length == 0) {
          this.$message.error('请勾选客户')
        } else {
          let ids = []
          this.selectRow.forEach((i) => {
            ids.push(i.id)
          })
          this.$refs.batchEdit.cust_ids = ids.join()
          this.$refs.batchEdit.showDialog = true
        }
      },
      // 选择表格
      selectTable(selection) {
        console.log('selection', selection)
        this.selectRow = selection
      },
      changeTable(a) {
        console.log('a', a)
      },
      // 筛选重复客户
      handleFilter() {
        this.$refs.filterClient.showDialog = true
      },
      // 合并客户
      handleMerge() {
        if (this.selectRow.length != 2) {
          this.$message.error('请选择两个客户合并')
        } else {
          // this.$refs.mergeClient.clients = JSON.parse(
          //   JSON.stringify(this.selectRow)
          // )
          let ids = []
          this.selectRow.forEach((i) => {
            ids.push(i.id)
          })
          this.$refs.mergeClient.ids = ids
          this.$refs.mergeClient.showDialog = true
        }
      },
      handleMap() {
        this.$refs.myMap.form = this.formInline
        this.$refs.myMap.showDialog = true
      },
    },
  }
</script>
<style lang="scss" scoped>
  .wrapper {
    padding: 20px;
  }
  .selectcls {
    width: 100px;
  }
  .btndiv {
    margin: 10px 5px -22px;
  }
  .cxcls .el-form-item--mini.el-form-item,
  .cxcls .el-form-item--small.el-form-item {
    margin-bottom: 10px;
  }
  .tablecls {
    width: 100%;
    margin: 15px 0;
  }
  .centerdw {
    display: flex;
    justify-content: center;
    margin: -25px 0 20px 0;
  }
  .addcls {
    padding: 50px 0 50px 13px;
    font-size: 20px;
    color: #909399;
  }
  .bm-view {
    width: 100%;
    height: 300px;
  }
</style>
