<template>
  <el-dialog
    title="客户分组"
    :visible.sync="showDialog"
    width="1000px"
    top="15vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <div class="orderTest-container">
      <div style="display: flex; align-items: center; margin-bottom: 10px">
        <div style="width: 110px; margin-right: 10px; text-align: right">
          客户组名称:
        </div>
        <el-input v-model="name" style="width: 260px"></el-input>
      </div>
      <el-table stripe :data="sortList">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          align="center"
        >
          <template #default="{ row }">
            <div v-if="item.label == '排序'">
              <el-input
                v-model.number="row.sort"
                type="number"
                @change="handlSort($event, row)"
              ></el-input>
            </div>
            <div v-else-if="item.label == '客户名称'">
              <client-search
                :popper-class="'selectDC'"
                :keyword="row.cust_name"
                @select-val-alldata="clientSelect($event, row)"
              ></client-search>
            </div>
            <div v-else>
              {{ row[item.prop] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop=""
          align="center"
          label="操作"
          fixed="right"
          width="120px"
        >
          <template #default="{ $index, row }">
            <el-button
              type="text"
              @click.native.prevent="handleAdd($index, row)"
            >
              添加
            </el-button>
            <el-button
              type="text"
              @click.native.prevent="handleDelete($index, row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="dialog-footer">
      <el-button type="primary" @click="handleSub">提交</el-button>
      <el-button @click="showDialog = false">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'

  import { postAction } from '@/api/Employee'
  import ClientSearch from '@/baseComponents/clientSearch'

  export default {
    name: 'Add',
    components: { ClientSearch },
    data() {
      return {
        showDialog: false,
        name: '',
        list: [],
        columns: [
          {
            order: 1,
            label: '排序',
            prop: '',
            width: '90',
          },
          {
            order: 2,
            label: '客户名称',
            prop: '',
            width: '',
          },
          {
            order: 3,
            label: '销售区域',
            prop: 'area_name',
            width: '140',
          },
          {
            order: 4,
            label: '渠道',
            prop: 'channel_name',
            width: '90',
          },
          {
            order: 5,
            label: '等级',
            prop: 'class_name',
            width: '90',
          },
        ],
      }
    },
    computed: {
      sortList() {
        return _.sortBy(this.list, (item) => item.sort_order)
      },
    },
    watch: {
      showDialog(v) {
        if (v) {
          this.handleAdd()
        } else {
          this.name = ''
          this.list = []
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      // 新建分组
      handleSub() {
        let goods = JSON.parse(JSON.stringify(this.sortList))
        let goods_data = []
        goods.forEach((item) => {
          item.cust_id = item.id
          if (item.id) {
            goods_data.push(item)
          }
        })
        goods_data = goods_data.length == 0 ? '[]' : JSON.stringify(goods_data)
        postAction('/baseAdmin/customer-group/create', {
          name: this.name,
          cust_data: goods_data,
        }).then((r) => {
          this.$emit('refresh')
          this.showDialog = false
          this.$message.success('添加成功')
        })
      },
      handleSelect(val, row) {
        row.specs = val.specs
        row.goods_id = val.goods_id
        row.goods_name = val.goods_name
      },
      handleAdd(index, row) {
        this.list.push({
          id: '',
          area_name: '',
          channel_name: '',
          sort: 0,
          sort_order: 0,
          class_name: '',
        })
      },
      handleDelete(index, row) {
        if (this.rlist?.length == 1) {
          this.$message.error('不能删除最后一行')
        } else {
          if (this.list?.length == 1) {
            this.$message.error('不能删除最后一行')
          } else {
            this.list?.splice(index, 1)
          }
        }
      },
      handlSort(val, row) {
        row.sort_order = val
      },
      clientSelect(val, row) {
        console.log(123)
        Object.assign(row, val)
      },
    },
  }
</script>
<style lang="scss" scoped></style>
