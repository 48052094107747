<template>
  <el-dialog
    title="合并客户"
    :visible.sync="showDialog"
    width="1200px"
    top="15vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <div class="orderTest-container">
      <el-table stripe :data="clients">
        <el-table-column
          v-for="(item, tableIndex) in check"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          width=""
          align="center"
        >
          <template #default="{ row, $index }">
            <div v-if="item.label == '门头照片'">
              <el-image
                v-if="row.photo1"
                style="width: 100px; height: 100px"
                :src="row.photo1"
                :preview-src-list="[row.photo1]"
              ></el-image>
              <span v-else></span>
            </div>
            <div v-else-if="item.label == '合并选择'">
              <el-radio v-model="isCheck" :label="$index">保留</el-radio>
            </div>
            <div v-else>{{ row[item.prop] }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="dialog-footer" style="margin-top: 25px">
      <el-button type="primary" @click="handleMerge">确认合并</el-button>
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/Employee'
  export default {
    name: 'Merge',
    components: {},
    data() {
      return {
        showDialog: false,
        clients: [],
        ids: null,
        isCheck: 0,
        check: [
          { width: '', label: '商铺名称', prop: 'cust_name' },
          { width: '', label: '联系人', prop: 'boss' },
          { width: '', label: '电话', prop: 'mobile' },
          { width: '110px', label: '门头照片', prop: 'photo1' },
          { width: '220px', label: '地址', prop: 'address' },
          { width: '', label: '创建时间', prop: 'create_at' },
          { width: '', label: '合并选择', prop: '' },
        ],
        columns: [
          {
            order: 0,
            label: '',
            prop: 'name',
            width: '120px',
          },
          {
            order: 1,
            label: '客户数据一',
            prop: 'name1',
            width: '',
          },
          {
            order: 2,
            label: '客户数据二',
            prop: 'name2',
            width: '',
          },
        ],
      }
    },
    computed: {
      list() {
        let this_ = this
        let arr = [
          // {
          //   name: '商铺名称',
          //   name1: '',
          //   name2: '',
          // },
          // {
          //   name: '联系人',
          //   name1: '',
          //   name2: '',
          // },
          // {
          //   name: '电话',
          //   name1: '',
          //   name2: '',
          // },
          // {
          //   name: '门头照片',
          //   name1: '',
          //   name2: '',
          // },
          // {
          //   name: '地址',
          //   name1: '',
          //   name2: '',
          // },
          // {
          //   name: '创建时间',
          //   name1: '',
          //   name2: '',
          // },
          // {
          //   name: '合并选择',
          //   name1: '',
          //   name2: '',
          // },
        ]
        // this.clients.forEach((client, index) => {})
        // Object.keys(this.clients[0]).forEach((key) => {
        //   arr.push({ [key]: this.clients[0][key] })
        // })
        // this.check.forEach((i, idx) => {
        //   console.log('aaa', i)
        //   this.clients.forEach((client, index) => {
        //     Object.keys(client).forEach((key) => {
        //       let obj = {
        //         name: i.name,
        //         name1: this_.clients[index][key],
        //         name2: this_.clients[index][key],
        //       }
        //       arr.push(obj)
        //     })
        //   })
        // })
        return arr
      },
    },
    watch: {
      showDialog(v) {
        if (v) {
          this.fetchData()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data } = await postAction('/baseAdmin/customer-info/merge-info', {
          ids: this.ids,
        })
        this.clients = data
        console.log('dd', data)
      },
      handleMerge() {
        postAction('/baseAdmin/customer-info/merge', {
          ids: this.ids,
          id: this.clients[this.isCheck].id,
        }).then((r) => {
          this.$message.success('合并成功')
          this.$emit('refresh')
          this.close()
        })
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
