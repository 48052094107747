<template>
  <el-dialog
    title="批量修改"
    :visible.sync="showDialog"
    width="500px"
    top="15vh"
    :modal="false"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="orderTest-container">
      <el-table stripe :data="list">
        <el-table-column
          prop="name"
          label="修改项目"
          width="110"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="selectList"
          label="修改为"
          width=""
          align="center"
        >
          <template #default="{ row }">
            <div v-if="row.name == '销售区域'">
              <sale-area
                :only-check-end="false"
                @set-sale-select-id="handleChooseAreaId"
              ></sale-area>
            </div>
            <div v-else-if="row.name == '价格方案'">
              <el-select
                v-model="row.value"
                clearable
                style="width: 160px"
                :placeholder="'请选择' + row.name"
              >
                <el-option
                  v-for="(i, idx) in row.selectList"
                  :key="idx"
                  :value="i.id"
                  :label="i.scheme_name"
                ></el-option>
              </el-select>
            </div>
            <div v-else-if="row.name == '线路'">
              <el-select
                v-model="row.value"
                clearable
                style="width: 160px"
                :placeholder="'请选择' + row.name"
              >
                <el-option
                  v-for="(i, idx) in row.selectList"
                  :key="idx"
                  :value="i.id"
                  :label="i.line_name"
                ></el-option>
              </el-select>
            </div>
            <div v-else-if="row.name == '网销归属'">
              <el-select
                v-model="row.value"
                clearable
                style="width: 160px"
                :placeholder="'请选择' + row.name"
              >
                <el-option
                  v-for="(i, idx) in row.selectList"
                  :key="idx"
                  :value="i.id"
                  :label="i.user_name"
                ></el-option>
              </el-select>
            </div>
            <div v-else>
              <el-select
                v-model="row.value"
                clearable
                :multiple="row.duoxuan"
                style="width: 160px"
                :placeholder="'请选择' + row.name"
              >
                <el-option
                  v-for="(i, idx) in row.selectList"
                  :key="idx"
                  :value="i.id"
                  :label="i.name"
                ></el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-popconfirm title="确认要封存选中的店铺吗？" @confirm="handlerSealing">
        <el-button slot="reference" style="margin-right: 180px">
          封存店铺
        </el-button>
      </el-popconfirm>
      <el-button type="primary" @click="handleSave">保存</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import saleArea from '@/baseComponents/saleAreaSelectTree'
  import {
    clientCustClass,
    clientCustChannel,
    batchEdit,
  } from '@/api/ClientDetail'
  import { getStaffList, getIndexList } from '@/api/setPrice'
  import { post_, postAction } from '@/api/Employee'
  export default {
    name: '',
    components: { saleArea },
    data() {
      return {
        showDialog: false,
        cust_ids: '',
        list: [
          {
            name: '客户等级',
            value: '',
            isSelect: true,
            duoxuan: false,
            selectList: [],
          },
          {
            name: '销售区域',
            value: '',
            isSelect: true,
            duoxuan: false,
            selectList: [],
          },
          {
            name: '渠道类型',
            value: '',
            isSelect: true,
            duoxuan: false,
            selectList: [],
          },
          {
            name: '价格方案',
            value: '',
            isSelect: true,
            duoxuan: false,
            selectList: [],
          },
          {
            name: '默认结算方式',
            value: '',
            isSelect: true,
            duoxuan: false,
            selectList: [
              {
                id: 1,
                name: '货到付款',
              },
              {
                name: '账期结算',
                id: 2,
              },
            ],
          },
          {
            name: '分配业务员',
            value: '',
            isSelect: true,
            duoxuan: true,
            selectList: [],
          },
          {
            name: '线路',
            value: '',
            isSelect: true,
            duoxuan: false,
            selectList: [],
          },
          {
            name: '网销归属',
            value: '',
            isSelect: true,
            duoxuan: false,
            selectList: [],
          },
        ],
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (!val) {
          this.list.forEach((i) => {
            i.value = ''
          })
        }
      },
    },
    created() {},
    mounted() {
      // 初始化下拉
      this.initSelect()
    },
    methods: {
      close() {
        this.showDialog = false
      },
      handleChooseAreaId(val) {
        this.list[1].value = val
      },
      initSelect() {
        this.clientLevelSelect()
        this.clientCustChannelfun()
        this.priceSelect()
        this.staffSelect()
        this.lineSelect()
        this.shopSelect()
      },
      clientLevelSelect() {
        clientCustClass().then((d) => {
          this.list[0].selectList = d.data
        })
      },
      clientCustChannelfun() {
        clientCustChannel().then((d) => {
          this.list[2].selectList = d.data
        })
      },
      priceSelect() {
        getIndexList({ pageSize: -1 }).then((d) => {
          this.list[3].selectList = d.data
        })
      },
      staffSelect() {
        getStaffList().then((d) => {
          this.list[5].selectList = d.data
        })
      },
      lineSelect() {
        post_('baseAdmin/common/line-list', {}).then((d) => {
          this.list[6].selectList = d.data
        })
      },
      shopSelect() {
        post_('baseAdmin/common/shop-staff-option', {}).then((d) => {
          this.list[7].selectList = d.data
        })
      },
      handlerSealing() {
        postAction('baseAdmin/customer-info/batch-update', {
          cust_ids: this.cust_ids,
          edit_objs: 9,
        }).then((d) => {
          this.$emit('refresh')
          this.$message.success('封存成功')
          this.close()
        })
      },
      handleSave() {
        let data = {
          edit_objs: [],
          class_id: '', //客户等级ID
          area_id: '', //区域ID
          channel_id: '', //渠道ID
          pay_way: '', //付款方式ID
          price_ids: '', //价格方案  用,分隔
          user_ids: '', //分配业务员 用,分隔
          line_ids: '',
          staff_id: '',
        }
        this.list.forEach((item, index) => {
          if (item.value != '') {
            data.edit_objs.push(index + 1)
          }
        })
        data.class_id = this.list[0].value
        data.area_id = this.list[1].value
        data.channel_id = this.list[2].value
        data.price_ids = this.list[3].value
        data.pay_way = this.list[4].value
        data.line_ids = this.list[6].value
        data.staff_id = this.list[7].value
        data.user_ids = this.list[5]
        if (data.user_ids.length != 0) {
          data.user_ids = this.list[5].value.join(',')
        } else {
          data.user_ids = ''
        }
        data.edit_objs = data.edit_objs.join()
        console.log('data', data)
        batchEdit({ ...data, ...{ cust_ids: this.cust_ids } }).then((res) => {
          this.$message.success('修改成功')
          this.$emit('refresh')
          this.close()
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
