var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "重复客户",
        visible: _vm.showDialog,
        width: "1100px",
        top: "5vh",
        "close-on-click-modal": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              attrs: {
                stripe: "",
                "row-key": "id",
                data: _vm.list,
                height: "500px",
              },
              on: { "selection-change": _vm.selectRow },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  type: "selection",
                  "reserve-selection": true,
                  width: "50",
                },
              }),
              _vm._l(_vm.finallyColumns, function (item, tableIndex) {
                return _c("el-table-column", {
                  key: tableIndex,
                  attrs: {
                    prop: item.prop,
                    label: item.label,
                    width: "",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            item.prop == "photo1"
                              ? _c(
                                  "div",
                                  [
                                    row.photo1
                                      ? _c("el-image", {
                                          staticStyle: {
                                            width: "100px",
                                            height: "100px",
                                          },
                                          attrs: {
                                            src: row.photo1,
                                            "preview-src-list": [row.photo1],
                                          },
                                        })
                                      : _c("span"),
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _vm._v(" " + _vm._s(row[item.prop]) + " "),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.form.pageNo,
              layout: _vm.layout,
              "page-size": _vm.form.pageSize,
              total: _vm.total,
              background: "",
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleMerge } },
            [_vm._v("合并")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
        ],
        1
      ),
      _c("merge", { ref: "merge", on: { refresh: _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }