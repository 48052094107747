<template>
  <el-dialog
    title="地图分布"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <baidu-map
      class="map"
      :center="center"
      :zoom="zoom"
      :scroll-wheel-zoom="true"
      @ready="handler"
    >
      <!--      ak="FzAhPfin2UmY2ZwHiqmGAl2XUlfSxUkY"-->
      <!-- 地图控件位置 -->
      <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
      <!-- 城市列表 -->
      <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
      <!-- 定位当前位置 -->
      <bm-geolocation
        anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
        :show-address-bar="true"
        :auto-location="true"
        @locationSuccess="getLoctionSuccess"
      ></bm-geolocation>
      <!-- 地图容器 -->
      <bm-view
        :style="{
          width: '100%',
          height: 500 + 'px',
          flex: 1,
          marginBottom: '-30px',
        }"
      ></bm-view>
      <!-- 标点 -->
      <bml-marker-clusterer :average-center="true">
        <bm-marker
          v-for="(marker, index) of list"
          :key="index"
          :mass-clear="false"
          :position="{ lng: marker.longitude, lat: marker.latitude }"
          @click="infoWindowOpen($event, marker, index)"
        >
          <!-- 信息窗口 -->
          <bm-info-window
            :show="marker.show"
            @close="infoWindowClose($event, marker)"
          >
            <div style="font-size: 18px; line-height: 36px">
              {{ marker.cust_name }}
            </div>
            <div>{{ marker.boss }}</div>
          </bm-info-window>
        </bm-marker>
      </bml-marker-clusterer>
    </baidu-map>
    <!-- <div class="demo-input-suffix">
      <el-link type="info">lng：</el-link>
      <el-input
        v-model.number="locData.longitude"
        class="lineinput"
        style="width: 200px"
        size="mini"
      ></el-input>
      <el-link type="info">lat：</el-link>
      <el-input
        v-model.number="locData.latitude"
        class="lineinput"
        style="width: 200px"
        size="mini"
      ></el-input>
      <el-link type="info">address：</el-link>
      <el-input
        v-model="locData.address"
        class="lineinput"
        style="width: 200px"
        size="mini"
      ></el-input>
    </div> -->
    <div slot="footer" class="dialog-footer">
      <el-button
        type="warning"
        size="small"
        icon="el-icon-close"
        @click.native="showDialog = false"
      >
        关闭
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/Employee'
  import { BmlMarkerClusterer } from 'vue-baidu-map'
  // import {
  //   BaiduMap,
  //   BmNavigation,
  //   BmView,
  //   BmGeolocation,
  //   BmCityList,
  // } from 'vue-baidu-map'
  export default {
    name: 'Map',
    components: { BmlMarkerClusterer },
    data() {
      return {
        form: {},
        list: [],
        show: false,
        showDialog: false,
        center: { lat: 38.04831193, lng: 114.5215319 },
        zoom: 12,
        locData: {
          longitude: '',
          latitude: '',
          address: '',
          loc_sheng: '',
          loc_shi: '',
          loc_qu: '',
        },
        clientHeight:
          document.documentElement.clientHeight / 2 < 100
            ? '200'
            : document.documentElement.clientHeight / 2, // 屏幕高度
      }
    },
    computed: {},
    watch: {
      showDialog(v) {
        if (v) {
          this.fetchData()
          // this.getLoctionSuccess()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      // /baseAdmin/customer-info/map
      async fetchData() {
        let { data } = await postAction(
          '/baseAdmin/customer-info/map',
          this.form
        )
        data.forEach((i) => {
          this.$set(i, 'show', false)
        })
        this.list = data

        console.log('data', data)
      },
      infoWindowClose(e, marker) {
        // marker.show = false
        this.$set(marker, 'show', false)
      },
      infoWindowOpen(e, marker, indxe) {
        // marker.show = true
        this.$set(marker, 'show', true)
        console.log('点击点', marker)
      },
      //点击地图监听
      clickEvent(e) {
        var that = this
        map.clearOverlays()

        let Icon_0 = new BMap.Icon(that.avatar, new BMap.Size(64, 64), {
          anchor: new BMap.Size(18, 32),
          imageSize: new BMap.Size(36, 36),
        })
        var myMarker = new BMap.Marker(
          new BMap.Point(e.point.lng, e.point.lat),
          { icon: Icon_0 }
        )
        map.addOverlay(myMarker)
        //用所定位的经纬度查找所在地省市街道等信息
        var point = new BMap.Point(e.point.lng, e.point.lat)
        var gc = new BMap.Geocoder()
        let _this = this
        gc.getLocation(point, function (rs) {
          console.log(rs)
          console.log(rs.addressComponents.city)
          var addComp = rs.addressComponents
          // console.log(rs.address) //地址信息
          _this.locData.address = rs.address
          _this.locData.loc_sheng = rs.addressComponents.province
          _this.locData.loc_shi = rs.addressComponents.city
          _this.locData.loc_qu = rs.addressComponents.district
        })
        this.locData.longitude = e.point.lng
        this.locData.latitude = e.point.lat
      },
      //定位成功回调
      getLoctionSuccess(point, AddressComponent, marker) {
        var that = this
        map.clearOverlays()
        let Icon_0 = new BMap.Icon(that.avatar, new BMap.Size(64, 64), {
          anchor: new BMap.Size(18, 32),
          imageSize: new BMap.Size(36, 36),
        })
        var myMarker = new BMap.Marker(
          new BMap.Point(point.point.lng, point.point.lat),
          { icon: Icon_0 }
        )
        map.addOverlay(myMarker)
        this.locData.longitude = point.point.lng
        this.locData.latitude = point.point.lat
      },
      handler({ BMap, map }) {
        let _this = this
        // 设置一个临时变量指向vue实例，因为在百度地图回调里使用this，指向的不是vue实例；
        var geolocation = new BMap.Geolocation()
        geolocation.getCurrentPosition(
          function (r) {
            console.log(r)
            _this.center = { lng: r.longitude, lat: r.latitude } // 设置center属性值
            _this.autoLocationPoint = { lng: r.longitude, lat: r.latitude } // 自定义覆盖物
            _this.initLocation = true
          },
          { enableHighAccuracy: true }
        )

        window.map = map
      },
      markerClick(v) {
        console.log('v', v)
      },
    },
  }
</script>
<style lang="scss" scoped></style>
