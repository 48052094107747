var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "合并客户",
        visible: _vm.showDialog,
        width: "1200px",
        top: "15vh",
        "close-on-click-modal": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-table",
            { attrs: { stripe: "", data: _vm.clients } },
            _vm._l(_vm.check, function (item, tableIndex) {
              return _c("el-table-column", {
                key: tableIndex,
                attrs: {
                  prop: item.prop,
                  label: item.label,
                  width: "",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return [
                          item.label == "门头照片"
                            ? _c(
                                "div",
                                [
                                  row.photo1
                                    ? _c("el-image", {
                                        staticStyle: {
                                          width: "100px",
                                          height: "100px",
                                        },
                                        attrs: {
                                          src: row.photo1,
                                          "preview-src-list": [row.photo1],
                                        },
                                      })
                                    : _c("span"),
                                ],
                                1
                              )
                            : item.label == "合并选择"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: $index },
                                      model: {
                                        value: _vm.isCheck,
                                        callback: function ($$v) {
                                          _vm.isCheck = $$v
                                        },
                                        expression: "isCheck",
                                      },
                                    },
                                    [_vm._v("保留")]
                                  ),
                                ],
                                1
                              )
                            : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer", staticStyle: { "margin-top": "25px" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleMerge } },
            [_vm._v("确认合并")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }