<template>
  <el-dialog
    title="禁销商品设置"
    :visible.sync="showban"
    width="600px"
    append-to-body
  >
    <div>
      <el-form ref="form">
        <el-form-item>
          <el-button @click="handleradd">添加商品</el-button>
          <goods-choose-dialog ref="choose" @add-rows="hanleraddrows" />
        </el-form-item>
      </el-form>
      <el-table :data="tabledata" style="width: 100%">
        <el-table-column
          prop="date"
          label="商品名称"
          align="center"
          width="220"
        >
          <template #default="{ row, $index }">
            <goods-search
              :goods_keyword="row.goods_name"
              :f-key="row.goods_name"
              :goods-id="row.goods_id"
              :rowf="row"
              @add-rows="hanleraddrows"
              @select-goods-all="selectGoods($event, row, $index)"
            ></goods-search>
            <!--            :depot-id="form.depot_id"-->
            <!--            :cust-id="form.id"-->
          </template>
        </el-table-column>
        <el-table-column
          prop="goods_specs"
          label="规格"
          align="center"
          width="180"
        ></el-table-column>
        <el-table-column prop="address" align="center" label="操作">
          <template #default="{ $index }">
            <el-button type="text" @click="tabledata.splice($index, 1)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showban = false">取 消</el-button>
      <el-button type="primary" @click="handlersave">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import GoodsChooseDialog from '@/baseComponents/goodsChooseDialog'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'Bangoods',
    components: { GoodsChooseDialog, GoodsSearch },
    data() {
      return {
        form: {
          id: null,
        },
        showban: false,
        tabledata: [],
      }
    },
    methods: {
      handlersave() {
        const goods_ids = this.tabledata.map((list) => {
          return list.goods_id
        })
        postAction('/baseAdmin/customer-info/unsale-update', {
          id: this.form.id,
          goods_ids: goods_ids.join(','),
        }).then((res) => {
          if (res.code == 200) {
            this.$message.success(res.msg)
            this.showban = false
            this.$emit('getlist')
          }
        })
      },
      handlerinfo() {
        postAction('/baseAdmin/customer-info/unsale-list', {
          id: this.form.id,
        }).then((res) => {
          if (res.code == 200) {
            this.tabledata = res.data
          }
        })
      },
      hanleraddrows(rows) {
        console.log(rows)
        const goodsdata = rows.map((list) => {
          return {
            goods_id: list.goods_id,
            goods_name: list.goods_name,
            goods_specs: list.specs,
          }
        })
        this.tabledata.push(...goodsdata)
      },
      selectGoods(val, row, index) {
        console.log(row, val)
        const valdata = {
          goods_id: val.goods_id,
          goods_name: val.goods_name,
          goods_specs: val.specs,
        }
        Object.assign(row, valdata)
      },
      handleradd() {
        this.$refs.choose.showDialog = true
      },
    },
  }
</script>

<style scoped></style>
